import _, { update } from "lodash";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  Table,
} from "reactstrap";
import Axios from "../../axios-interceptor";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { recordLoadEnd, recordLoadStart } from "../../redux/actions/appActions";
import { Link } from "react-router-dom";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
// import logo from "../assets/img/avatars/modal-logo.png";
import logo from "../../assets/img/avatars/modal-logo.png";
import { each } from "jquery";
import { setProjects } from "../../../src/redux/actions/projectsActions";
import SelectWrapper from "../../components/SelectWrapper/SelectWrapper";

function ProjectScheduleWBSLevel({
  profile,
  project,
  setScheduleDate,
  isScheduleDates,
  getprojectmanagerlist
}) {
  const [selectedMethodologies, setSelectedMethodologies] = useState([]);
  const [wbsNumbers, setWbsNumbers] = useState([]);
  const [scheduleDate, setScheduleDates] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [isHistoryOpen, setHistoryOpen] = useState(false);
  const [historyDatatoDisplay, setHistoryDatatoDisplay] = useState([]);
  const [isclosed, setisClosed] = useState([]);
  const [isCancelled, setIsCancelled] = useState([]);
  const [assignProjectManager, setAssignProjectManager] = useState(false);
  const [currentProject, setCurrentProject] = useState(project);
  const [enableUpdate, setEnableUpdate] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  // const [getprojectmanagerlist, setGetPorjectManagerList] = useState([]);
  const [currproj, setCurrProj] = useState([]);
  const [isActualFWStartDateTouched, setIsActualFWStartDateTouched] = useState(false);
  const [isActualFWStartDateSaved, setIsActualFWDateSaved] = useState({});
  const [allDates, setAllDates] = useState({
    FWStartDate: "",
    FWEndDate: "",
    QuestionnaireDate: "",
  });
  let [enableonlyonerow, setEnableOnlyOneRow] = useState([]);
  let [wbsnumber, setWbsNumber_sd] = useState({
    wbsnumber_sd: "",
    label_sd: "",
  });
  let [acceptPM, setAcceptPM] = useState({});
  const [savedInDB, setSavedInDB] = useState(false);

  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const projects = useSelector(({ projects }) => projects.items);
  const recordloading = useSelector(({ app }) => app.recordloading);
  const dispatch = useDispatch();
  // const userRecord = useSelector(({ user }) => user.userRecord);
  const exceptionMethodology = ["Desk Research"];
  const exceptionMethToCheck = "Desk Research";
  const [dropdownValue, setDropdownValue] = useState([]);

  useEffect(() => {
    console.log(project);
    let meths = _.cloneDeep(selectedMethodologies);
    if (profile?.id && project?.id)
      profile?.CountrySpecs.map((cs) =>
        cs?.MethodologySpecs.map((ms) => {
          let opportunityLineItemDetails = [];
          project.ContractDetails.forEach((cd) => {
            opportunityLineItemDetails = [
              ...opportunityLineItemDetails,
              ...cd.opportunityLineItemDetails,
            ];
          });
          let lineItemIds = _.uniq(
            ms.CostingOptions.filter((co) => co.CoSelectedForSummary).map(
              (co) => co.OpportunityLineItemID
            )
          );
          if (opportunityLineItemDetails.length) {
            if (
              opportunityLineItemDetails
                .filter((ol) => _.includes(lineItemIds, ol.id))
                .every((ol) => ol.WBSNumber)
            ) {
              let _wbs = lineItemIds.map(
                (li) =>
                  opportunityLineItemDetails.find((ol) => ol.id == li)
                    ?.WBSNumber
              );

              if (
                _wbs?.length &&
                !ms.NotApplicable &&
                !meths.filter((sm) => sm.Code == ms.Code).length
              ) {
                meths.push({
                  Code: ms.Code,
                  Label: ms.Label,
                  id: ms.id,
                  wbsnumbers: _wbs,
                });
              }
            } else {
              toastr.error("WBS is Missing");
            }
          } else {
            toastr.error("Opportunity Details Missing this Project");
          }
        })
      );

    console.log(meths);
    setSelectedMethodologies(meths);
    fetchSchedueDates();
  }, []);
  // console.log(selectedMethodologies,"setSelectedMethodologies")
  const fetch = async () => {
    await Axios.get("costingProfiles/gethistoryDateAdjustments/" + profile.id, {
      headers: { "auth-token": localStorage.getItem("auth-token") },
    })
      .then((data) => {
        setHistoryDatatoDisplay(data.data.data);
        console.log(data.data.data);
      })
      .catch((err) => {
        console.log("History Data Loadig Failed");
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  console.log(historyDatatoDisplay)

  // useEffect(() => {
  //   // dispatch({ type: "SEARCH_TYPE", SearchingType: "ID" });
  //   // dispatch({ type: currentCostingActions.CLEAR_NEW_COSTING });
  //   // dispatch({ type: currentProjectActions.CLEAR_NEW_PROJECT });
  //   Axios.get("/users/opspms/all")
  //     .then((res) => {
  //       setGetPorjectManagerList(res.data.users);
  //     })

  //     .catch((err) => {
  //       // dispatch(appActions.pageLoadEnd());
  //     });
  // }, []);

  const fetchSchedueDates = async () => {
    dispatch(recordLoadStart());
    await Axios.get("/users/")
    .then(async (res) => {
      await Axios.get(`/deliveries/getSchedule/${profile.id}`)
      .then(async (data) => {
        // console.log("/deliveries/getSchedule",data.data);
        if (data.data.data.length) {
          let wbsnumbers = data.data.data.map((number) => number.WBSNumber);
          let wbsnumbers1 = {};
          wbsnumbers1 = data.data.data.map((number) => {
            return {
              wbsnumber: number.WBSNumber,
              state: number.IsClosed,
            };
          });
          setisClosed(wbsnumbers1);
          let wbsnocancelled = {};
          wbsnocancelled = data.data.data.map((number) => {
            return {
              wbsnumber: number.WBSNumber,
              state: number.IsCancel,
            };
          });
          setIsCancelled(wbsnocancelled);
          // console.log(isclosed);
          // console.log("wbsnumbers1",wbsnumbers1);
          wbsnumbers = _.uniq(wbsnumbers);
          let products = wbsnumbers.map((wb) => {
            let product = _.uniq(
              data.data.data
                .filter((d) => d.WBSNumber == wb)
                .map((ele) => ele.Product)
            );
            return {
              wbs: wb,
              product: product.toString(),
            };
          });
  
          console.log(products);
          //   setSelectedMethodologiesWBS(products)
  
          let _dates = {};
          let _wbsnumbers = _.cloneDeep(wbsNumbers);
          let _sm = {};
          let csAndOpsUsers = [];
          let uniqueCsAndOpsUsers = [];          
          wbsnumbers.map((wbs) => {
            // console.log(wbs);
            data.data.data
              .filter((ele) => ele.WBSNumber === wbs)
              .map((rec) => {   
                let isDeskResearch = false;             
                if(rec?.SubmethodologyLabel?.split(',')
                  ?.map(item => item.trim())
                  ?.includes(exceptionMethToCheck) || true
                ) {
                  // setGetPorjectManagerList(...getprojectmanagerlist,res.data.users);
                  csAndOpsUsers = [...res?.data?.items];
                  const allUsers = csAndOpsUsers?.filter((user) => !user.IsDisabled && !user.IsDeleted);
                  uniqueCsAndOpsUsers = _.uniqBy(allUsers, 'Email');
                  uniqueCsAndOpsUsers?.sort((a, b) => a.Email.localeCompare(b.Email));
                  isDeskResearch = true;
                }
                    
                _sm = {
                  product: rec?.Product,
                  id: rec.id,
                  QueDate: new Date(rec.QuestionnaireDate),
                  FWStart: rec.FWStartDate ? new Date(rec.FWStartDate) : null,
                  FWEnd: rec.FWEndDate ? new Date(rec.FWEndDate) : null,
                  ActualFWStart: rec?.ActualFWStartDate ? new Date(rec.ActualFWStartDate) : null,
                  NewFWEnd: rec?.NewFWEndDate ? new Date(rec.NewFWEndDate) : null,
                  PlannedFWStart: rec?.PlannedFWstartdate ?  new Date(rec.PlannedFWstartdate) : new Date(rec.FWStartDate),
                  PlannedFWEnd: rec?.PlannedFWenddate ?  new Date(rec.PlannedFWenddate) : new Date(rec.FWEndDate),
                  OPSProjectManager: rec?.OPSProjectManager,
                  dropDownValue: uniqueCsAndOpsUsers,//uniqueCsAndOpsUsers.length ? uniqueCsAndOpsUsers : getprojectmanagerlist,
                  isDeskResearch: isDeskResearch
                };
                _dates[wbs] = _sm;
              });
            if (_wbsnumbers.find((wb) => wb.value == wbs)) {
            } else {
              _wbsnumbers.push({
                value: wbs,
                label: wbs,
                product: products.find((p) => p.wbs == wbs)?.product,
              });
            }
          });
          //  let dates =  data.data.data.map((d,i)=>{
  
          //     tempDAtes[d.SubmethodologyLabel] = {...tempDAtes[d.SubmethodologyLabel],"QueDate":new Date(d.QuestionnaireDate),"FWStart":new Date(d.FWStartDate),"FWEnd":new Date(d.FWEndDate)}
  
          //  })
          // console.log(_dates);
          setWbsNumbers(_wbsnumbers);
          // setisClosed();
          //   setSelectedProduct(_.head(_wbsnumbers).product)
          setIsActualFWDateSaved(_dates);
          setScheduleDates(_dates);
          setAcceptPM(_dates);
          // dispatch(recordLoadEnd());
        } else {
          let wbsnumbers = _.cloneDeep(wbsNumbers);
          if (project && project?.ContractDetails.length) {
            project.ContractDetails.map((cd) =>
              cd.opportunityLineItemDetails
                .filter((ol) => ol.WBSNumber)
                .map((ol) => {
                  if (
                    !wbsnumbers.filter((wb) => wb.value === ol.WBSNumber).length
                  )
                    wbsnumbers.push({
                      value: ol.WBSNumber,
                      label: ol.WBSNumber,
                      product: cd.opportunityLineItemDetails
                        .filter((ele) => ele.WBSNumber === ol.WBSNumber)
                        .map((lineitem) => lineitem.MaterialID)
                        .toString(),
                    });
                })
            );
            setWbsNumbers([...wbsnumbers]);
            // dispatch(recordLoadEnd());
            // setSelectedWBS(_.head(wbsnumbers).value)
            //   setSelectedProduct(_.head(wbsnumbers).product)
          }
        }
        dispatch(recordLoadEnd());
      })
      .catch((err) => {
        dispatch(recordLoadEnd())
      });
    })
    .catch((err) => {
      dispatch(recordLoadEnd())
    });

  };
  const handleJSONData = (data) => {
    let parsedData;
    
    if(typeof data === 'string') {
      try {
        parsedData = JSON.parse(data);
      } catch( err ) {
        console.error("Error parsing json", err);
      }
    } else if(typeof data === 'object') {
      parsedData = data;
    } else{
      console.error("Invalid data type", typeof data);
    }

    if(typeof parsedData === 'string') {
      try {
        parsedData = JSON.parse(parsedData);
      } catch( err ) {
        console.error("Error parsing json", err);
      }
    }
    return parsedData;
  };
  console.log("scheduleDate Values", scheduleDate);
  const saveProjectManager = (acceptStatus, updateStatus) => {
    let projectManager;
    let acceptProjectManager;
    projectManager = currproj.map((i) => {
      return i.value;
    });

    // let tempDates = _.cloneDeep(scheduleDate);
    let _historyData = _.cloneDeep(historyData);
    let rec = _historyData.find(
      (wb) => wb.WBSNumber == wbsnumber.wbsnumber_sd
      );
    if (acceptStatus && !updateStatus) {
      acceptProjectManager = _.cloneDeep(acceptPM);
      // console.log(acceptProjectManager, "acceptProjectManager");
      let _sm = acceptProjectManager[wbsnumber.wbsnumber_sd]
        ? {
            ...acceptProjectManager[wbsnumber.wbsnumber_sd],
            OPSProjectManager: projectManager.length
              ? projectManager.toString()
              : "",
          }
        : {
            ...acceptProjectManager,
            OPSProjectManager: projectManager.length
              ? projectManager.toString()
              : "",
          };
      acceptProjectManager[wbsnumber.wbsnumber_sd] = _sm;
    }
    let updateProjectManager = _.cloneDeep(scheduleDate);
    let _sm = updateProjectManager[wbsnumber.wbsnumber_sd]
      ? {
          ...updateProjectManager[wbsnumber.wbsnumber_sd],
          OPSProjectManager: projectManager.length
            ? projectManager.toString()
            : "",
        }
      : {
          ...updateProjectManager,
          OPSProjectManager: projectManager.length
            ? projectManager.toString()
            : "",
        };
    updateProjectManager[wbsnumber.wbsnumber_sd] = _sm;
    setScheduleDates(updateProjectManager);    

    if (rec) {
      _historyData = _historyData.map((ele) => {
        if (
          ele.WBSNumber == wbsnumber.wbsnumber_sd &&
          !ele.OPSProjectManager
        ) {
          return {
            ...ele,
            OldOPSProjectManager: updateProjectManager[wbsnumber.wbsnumber_sd]?.OPSProjectManager,
          };
        } else return ele;
      });
    } else {
      _historyData.push({
        OldQuestionnaireDate: updateProjectManager[wbsnumber.wbsnumber_sd]?.QueDate,
        SubmethodologyLabel: wbsNumbers.map((wb) => selectedMethodologies.map((sm, idx) => {
          if(_.includes(sm.wbsnumbers, wb.value) && (wb.value==wbsnumber.wbsnumber_sd)) return sm.Label})).flat()
            .filter(item => item !== undefined).join(', '),
        OldFWEndDate: updateProjectManager[wbsnumber.wbsnumber_sd]?.FWEnd,
        OldFWStartDate: updateProjectManager[wbsnumber.wbsnumber_sd]?.FWStart,
        OldOPSProjectManager: updateProjectManager[wbsnumber.wbsnumber_sd]?.OPSProjectManager,
        NewOPSProjectManager: currproj && currproj?.length ? currproj : "",
        WBSNumber: wbsnumber.wbsnumber_sd,
        CostingProfileId: profile.id,
        Product: wbsNumbers.find((wb) => wb.value == wbsnumber.wbsnumber_sd).product,
      });
    }
      setHistoryData((prev) => [...prev, ..._historyData]);
    // console.log(updateProjectManager, "updatepjm")
    // let pmAssigned = "";
    // Object.keys(scheduleDate).map((wb) => {
    //   Object.keys(scheduleDate[wb]).map((sd) => {
    //     pmAssigned = acceptProjectManager[wb][sd].OPSProjectManager ? acceptProjectManager[wb][sd].OPSProjectManager : ''
    //   })
    // })
    let filtered2;
    if (historyData.length > 0) {
      const asArray = Object.entries(scheduleDate);
      // console.log(asArray, "asArray")
      const filtered = asArray.filter(
        ([key, value]) => historyData[0].WBSNumber === key
      );
      // console.log(filtered)
      // const asArray2 = Object.entries(filtered[0][1]);
      filtered2 = filtered[0][1];
      // console.log(filtered)
    }
    let acceptDates;
    const acceptArray = Object.entries(scheduleDate);
    // console.log(acceptArray)
    const filteredAccept = acceptArray.filter(
      ([key, value]) => wbsnumber.wbsnumber_sd === key
    );
    // console.log(filteredAccept)
    // const acceptArray2 = Object.entries(filteredAccept[0][1]);
    acceptDates = filteredAccept[0][1];
    console.log("final Values", acceptDates);

    let dateFWStart = acceptDates?.FWStart?.toLocaleDateString();
    let dateFWEnd = acceptDates?.FWEnd?.toLocaleDateString();
    let dateQue = acceptDates?.QueDate?.toLocaleDateString();
    setEnableUpdate(false);
    dispatch(recordLoadStart());
    saveDatesWbs(
      updateProjectManager,
      filtered2,
      historyData,
      acceptProjectManager,
      acceptStatus,
      updateStatus
    );
    let latest_history;
    latest_history = historyData;
    Axios.put(`/projects/${currentProject.ProjectId}/UpdateProjectParams`, {
      ProjectManagerEmail: currentProject.ProjectManagerEmail,
    })
    .then((res) => {
      dispatch(recordLoadEnd());
      //todo: sendemail
      //use SendProjectAssignEmail
      Axios.post(
        `/utils/mail/${currentProject.ProjectId}/SendProjectAssignEmail`,
        {
          ProjectManagerEmail: currproj,
          acceptStatus: acceptStatus,
          updateStatus: updateStatus,
          historyData: latest_history ? latest_history : "",
          allDates: filtered2 ? filtered2 : "",
          wbsnumber: wbsnumber.wbsnumber_sd ? wbsnumber.wbsnumber_sd : "",
          submethodology: wbsnumber.label_sd ? wbsnumber.label_sd : "",
          dateFWStart: dateFWStart ? dateFWStart : "",
          dateFWEnd: dateFWEnd ? dateFWEnd : "",
          dateQue: dateQue ? dateQue : "",
        }
      )
      .then((res) => {
        toastr.success(
          "Mail Sent Successfully",
          "Mail Sent to Selected Project Managers"
        );
      })
      .catch((error) => {
        toastr.error(
          "Mail Sending failed",
          "Mail Sending to Selected Project Managers failed"
        );
      });
      setAssignProjectManager(false);
      setShowModal(false);
      setShowUpdateModal(false);
      // dispatch(currentProjectActions.getProject(currentProject.ProjectId));
      let currrentProj;
         Axios.get(`/projects/${currentProject.ProjectId}`).then((res)=>{
        // dispatch(saveProject(res.data.project,null,null))
        console.log("projectproject", res.data.project);
        currrentProj = res.data.project;
        // currentProject = res.data.project;
        // debugger;
        // project = res.data.project;
        let existingProjects = projects.map((it) => {
          if (it.id == currentProject.id) {
            return { ...currrentProj };
          } else return { ...it };
        });
        dispatch(setProjects({ items: [...existingProjects] }));
  
        toastr.success(
          "Successfully Updated",
          "Assigning Project Manager Successful"
        );
      })
      
    })
    .catch((error) => {
      setAssignProjectManager(false);
      dispatch(recordLoadEnd());
      toastr.error(
        "Something went wrong",
        "Assigning Project Manager Failed"
      );
    });
  };
  const onChangeHandlerUpdate = (wbsnumber, modalName) => {
    setWbsNumber_sd({
      wbsnumber_sd: wbsnumber,
      // label_sd: label,
    });
    
    if(modalName == "Accept") {
      setShowModal(true);
    } else {
      setShowUpdateModal(true);
    }
    if(scheduleDate &&
      scheduleDate[wbsnumber] &&
      scheduleDate[wbsnumber]?.dropDownValue
    )
      setDropdownValue(scheduleDate[wbsnumber]?.dropDownValue);

      let tempDates = _.cloneDeep(scheduleDate);
      let _historyData = _.cloneDeep(historyData);
      let rec = _historyData.find(
        (wb) => wb.WBSNumber == wbsnumber
      );

      if (rec) {
        _historyData = _historyData.map((ele) => {
          if (
            ele.WBSNumber == wbsnumber &&
            !ele.OPSProjectManager
          ) {
            return {
              ...ele,
              OldOPSProjectManager: tempDates[wbsnumber]?.OPSProjectManager,
            };
          } else return ele;
        });
      } else {
        _historyData.push({
          OldQuestionnaireDate: tempDates[wbsnumber]?.QueDate,
          SubmethodologyLabel: wbsNumbers.map((wb) => selectedMethodologies.map((sm, idx) => {
            if(_.includes(sm.wbsnumbers, wb.value) && (wb.value==wbsnumber)) return sm.Label})).flat()
              .filter(item => item !== undefined).join(', '),
          OldFWEndDate: tempDates[wbsnumber]?.FWEnd,
          OldFWStartDate: tempDates[wbsnumber]?.FWStart,
          OldOPSProjectManager: tempDates[wbsnumber]?.OPSProjectManager,
          NewOPSProjectManager: currproj && currproj?.length ? currproj : "",
          WBSNumber: wbsnumber,
          CostingProfileId: profile.id,
          Product: wbsNumbers.find((wb) => wb.value == wbsnumber).product,
        });
      }
      setHistoryData((prev) => [...prev, ..._historyData]);
      // setCurrProj([]);
  };

  const onChangeHandlerQueDates = (e, id, selectedWBS) => {
    !_.isEqual(acceptPM?.[selectedWBS]?.QueDate, e)
      ? setEnableUpdate(true)
      : setEnableUpdate(false);

    setEnableOnlyOneRow([...enableonlyonerow, selectedWBS]);
    setAllDates({ ...allDates, QuestionnaireDate: e });
    let tempDates = _.cloneDeep(scheduleDate);
    let _historyData = _.cloneDeep(historyData);
    let rec = _historyData.find(
      (wb) => wb.WBSNumber == selectedWBS
    );
    // if (tempDates.length == undefined) {
      // let tempDate = _.cloneDeep(scheduleDate);
      // if (
      //   tempDate[selectedWBS] &&
      //   tempDate[selectedWBS].FWStart &&
      //   tempDate[selectedWBS].FWStart?.getTime() <= e?.getTime()
      // ) {
        // let tempDates = _.cloneDeep(scheduleDate);

        // let _sm = tempDates[selectedWBS]
        //   ? { ...tempDates[selectedWBS][sm], [id]: e,FWStart:null,FWEnd:null }
        //   : { ...tempDates[sm], [id]: e };
        // tempDates[selectedWBS] = { ...tempDates[selectedWBS], [sm]: _sm };
        // // tempDates[sm] = {...tempDates[sm],WBS:selectedWBS}
        // console.log(tempDates);
        // setScheduleDates(tempDates);
      //   toastr.info("Final Questionnaire Delivery Date should be less than Initial Planned FW Start Date");
      // } else {
      //   let _sm = tempDate[selectedWBS]
      //     ? { ...tempDate[selectedWBS], [id]: e }
      //     : { ...tempDate, [id]: e };
      //   tempDate[selectedWBS] = _sm;
        // tempDates[sm] = {...tempDates[sm],WBS:selectedWBS}
      //   console.log(tempDate);
      //   setScheduleDates(tempDate);
      // }
    // } else {
      if (rec) {
        _historyData = _historyData.map((ele) => {
          if (
            ele.WBSNumber == selectedWBS &&
            !ele.OldQuestionnaireDate
          ) {
            return {
              ...ele,
              OldQuestionnaireDate: tempDates[selectedWBS][id],
            };
          } else return ele;
        });
      } else {
        _historyData.push({
          OldQuestionnaireDate: tempDates[selectedWBS][id],
          SubmethodologyLabel: wbsNumbers.map((wb) => selectedMethodologies.map((sm, idx) => {
            if(_.includes(sm.wbsnumbers, wb.value) && (wb.value==selectedWBS)) return sm.Label})).flat()
              .filter(item => item !== undefined).join(', '),
          OldFWEndDate: tempDates[selectedWBS].FWEnd,
          OldFWStartDate: tempDates[selectedWBS].FWStart,
          WBSNumber: selectedWBS,
          CostingProfileId: profile.id,
          Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
        });
      }
      setHistoryData((prev) => [...prev, ..._historyData]);

      // if (e < new Date(profile.CommissionedDate)) {
      //   let _sm = tempDates[selectedWBS]
      //     ? { ...tempDates[selectedWBS][sm], [id]: null }
      //     : { ...tempDates[sm], [id]: null };
      //   tempDates[selectedWBS] = { ...tempDates[selectedWBS], [sm]: _sm };

      //   setScheduleDates(tempDates);

      //   toastr.info(
      //     "Questionnare Date Should be Greater than Commissioning Date"
      //   );
      // } else
      if (
        tempDates[selectedWBS].FWStart &&
        tempDates[selectedWBS].FWStart?.getTime() <= e?.getTime()
      ) {
        // let _sm = tempDates[selectedWBS]
        //   ? { ...tempDates[selectedWBS][sm], FWStart: null, FWEnd: null  }
        //   : { ...tempDates[sm],[id]:null};
        // tempDates[selectedWBS] = { ...tempDates[selectedWBS], [sm]: _sm };

        // setScheduleDates(tempDates);

        toastr.info("Final Questionnaire Delivery Date should be less than Initial Planned FW Start Date");
      } else {
        let tempDates = _.cloneDeep(scheduleDate);
        let temp = {
          SubmethodologyLabel: wbsNumbers.map((wb) => selectedMethodologies.map((sm, idx) => {
            if(_.includes(sm.wbsnumbers, wb.value) && (wb.value==selectedWBS)) return sm.Label})).flat()
              .filter(item => item !== undefined).join(', '),
          QuestionnaireDate: new Date(e),
          OldQuestionnaireDate: tempDates[selectedWBS][id],
          OldFWEndDate: tempDates[selectedWBS]?.FWEnd,
          OldFWStartDate: tempDates[selectedWBS]?.FWStart,
          WBSNumber: selectedWBS,
          CostingProfileId: profile.id,
          Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
        };

        let _historyData = _.cloneDeep(historyData);
        console.log(_historyData,"_historyData");
        let rec = _historyData.find(
          (wb) => wb.WBSNumber == selectedWBS
        );
        if (rec) {
          _historyData = _historyData.map((ele) => {
            if (
              ele.WBSNumber == selectedWBS &&
              ele.OldQuestionnaireDate
            ) {
              return { ...ele, QuestionnaireDate: e };
            } else if (
              ele.WBSNumber == selectedWBS &&
              !ele.OldQuestionnaireDate
            ) {
              return {
                ...ele,
                QuestionnaireDate: e,
                OldQuestionnaireDate: tempDates[selectedWBS][id],
              };
            } else return ele;
          });
        } else {
          _historyData.push(temp);
        }
        console.log(_historyData);

        setHistoryData(_historyData);
        let _sm = tempDates[selectedWBS]
          ? { ...tempDates[selectedWBS], [id]: e }
          : { ...tempDates, [id]: e };
        tempDates[selectedWBS] = _sm;
        // tempDates[sm] = {...tempDates[sm],WBS:selectedWBS}
        console.log(tempDates);
        setScheduleDates(tempDates);
      }
    // }
  };

  const onChangeHandlerFWStart = (e, id, selectedWBS) => {
    setEnableOnlyOneRow([...enableonlyonerow, selectedWBS]);
    setAllDates({ ...allDates, FWStartDate: e });
    !_.isEqual(acceptPM?.[selectedWBS]?.FWStart, e)
      ? setEnableUpdate(true)
      : setEnableUpdate(false);
    let tempDates = _.cloneDeep(scheduleDate);

    let _historyData = _.cloneDeep(historyData);
    let rec = _historyData.find(
      (wb) => wb.WBSNumber == selectedWBS
    );
    // console.log(_historyData, "_historyData");
    if (rec) {
      _historyData = _historyData.map((ele) => {
        if (
          ele.WBSNumber == selectedWBS &&
          !ele.OldFWStartDate
        ) {
          return { ...ele, OldFWStartDate: tempDates[selectedWBS][id] };
        } else return ele;
      });
    } else {
      _historyData.push({
        OldFWStartDate: tempDates[selectedWBS][id],
        SubmethodologyLabel: wbsNumbers.map((wb) => selectedMethodologies.map((sm, idx) => {
          if(_.includes(sm.wbsnumbers, wb.value) && (wb.value==selectedWBS)) return sm.Label})).flat()
            .filter(item => item !== undefined).join(', '),
        OldFWEndDate: tempDates[selectedWBS]?.FWEnd,
        OldQuestionnaireDate: tempDates[selectedWBS]?.QueDate,
        WBSNumber: selectedWBS,
        CostingProfileId: profile.id,
        Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
      });
    }

    setHistoryData(_historyData);

    if (tempDates[selectedWBS] && tempDates[selectedWBS].QueDate) {
      if (e?.getTime() <= tempDates[selectedWBS].QueDate?.getTime()) {
        let _sm = tempDates[selectedWBS]
          ? { ...tempDates[selectedWBS], [id]: tempDates[selectedWBS]?.FWStart }
          : { ...tempDates, [id]: tempDates[selectedWBS]?.FWStart };
        tempDates[selectedWBS] = _sm;

        setScheduleDates(tempDates);
        toastr.info("Initial Planned FW Start Date should be greater than Final Questionnaire Delivery Date");
      } else if (
        tempDates[selectedWBS].FWEnd &&
        tempDates[selectedWBS].FWEnd <= e
      ) {
        // let _sm = tempDates[selectedWBS]
        //   ? { ...tempDates[selectedWBS][sm],FWEnd:null}
        //   : { ...tempDates[sm], FWStart: null, };
        // tempDates[selectedWBS] = { ...tempDates[selectedWBS], [sm]: _sm };

        // setScheduleDates(tempDates);

        toastr.info("Initial Planned FW Start Date should be less than Initial Planned FW End Date");
      } else {
        let tempDates = _.cloneDeep(scheduleDate);
        let temp = {
          SubmethodologyLabel: wbsNumbers.map((wb) => selectedMethodologies.map((sm, idx) => {
            if(_.includes(sm.wbsnumbers, wb.value) && (wb.value==selectedWBS)) return sm.Label})).flat()
              .filter(item => item !== undefined).join(', '),
          FWStartDate: new Date(e),
          OldFWStartDate: tempDates[selectedWBS][id],
          OldFWEndDate:tempDates[selectedWBS]?.FWEnd,
          OldQuestionnaireDate: tempDates[selectedWBS]?.QueDate,
          WBSNumber: selectedWBS,
          CostingProfileId: profile.id,
          Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
        };
        let _historyData = _.cloneDeep(historyData);
        let rec = _historyData.find(
          (wb) => wb.WBSNumber == selectedWBS
        );

        if (rec) {
          _historyData = _historyData.map((ele) => {
            if (
              ele.WBSNumber == selectedWBS &&
              ele[`Old${id}Date`]
            ) {
              return { ...ele, FWStartDate: e };
            } else if (
              ele.WBSNumber == selectedWBS &&
              !ele[`Old${id}Date`]
            ) {
              return {
                ...ele,
                FWStartDate: e,
                OldFWStartDate: tempDates[selectedWBS][id],
              };
            } else return ele;
          });
        } else {
          _historyData.push(temp);
        }

        setHistoryData(_historyData);
        console.log(_historyData);

        let _sm = tempDates[selectedWBS]
          ? { ...tempDates[selectedWBS], [id]: e, PlannedFWStart: e }
          : { ...tempDates, [id]: e, PlannedFWStart: e };

        tempDates[selectedWBS] = _sm;

        setScheduleDates(tempDates);
      }
    } else {
      let _sm = tempDates[selectedWBS]
        ? { ...tempDates[selectedWBS], [id]: null }
        : { ...tempDates, [id]: null };
      tempDates[selectedWBS] = _sm;

      setScheduleDates(tempDates);
      toastr.error("Please Select Questionnaire Date");
    }
    console.log(tempDates);
  };

  const onChangeHandlerPlannedFWStart = (e, id, selectedWBS) => {
    setEnableOnlyOneRow([...enableonlyonerow, selectedWBS]);
    setAllDates({ ...allDates, PlannedFWstartdate: e });
    !_.isEqual(acceptPM?.[selectedWBS]?.PlannedFWStart, e)
      ? setEnableUpdate(true)
      : setEnableUpdate(false);
    let tempDates = _.cloneDeep(scheduleDate);

    let _historyData = _.cloneDeep(historyData);
    let rec = _historyData.find(
      (wb) => wb.WBSNumber == selectedWBS
    );
    if (rec) {
      _historyData = _historyData.map((ele) => {
        if (
          ele.WBSNumber == selectedWBS &&
          !ele.OldPlannedFWstartdate
        ) {
          return { ...ele, OldPlannedFWstartdate: tempDates[selectedWBS][id] };
        } else return ele;
      });
    } else {
      _historyData.push({
        OldPlannedFWstartdate: tempDates[selectedWBS][id],
        SubmethodologyLabel: wbsNumbers.map((wb) => selectedMethodologies.map((sm, idx) => {
          if(_.includes(sm.wbsnumbers, wb.value) && (wb.value==selectedWBS)) return sm.Label})).flat()
            .filter(item => item !== undefined).join(', '),
        OldPlannedFWenddate: tempDates[selectedWBS]?.PlannedFWEnd,
        OldFWStartDate: tempDates[selectedWBS]?.FWStart,
        OldFWEndDate:tempDates[selectedWBS]?.FWEnd,
        OldQuestionnaireDate: tempDates[selectedWBS]?.QueDate,
        OldOPSProjectManager: tempDates[selectedWBS]?.OPSProjectManager,
        WBSNumber: selectedWBS,
        CostingProfileId: profile.id,
        Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
      });
    }

    setHistoryData(_historyData);

    if (tempDates[selectedWBS] && tempDates[selectedWBS].QueDate) {
      // if (e?.getTime() < tempDates[selectedWBS].QueDate?.getTime()) {
      //   let _sm = tempDates[selectedWBS]
      //     ? { ...tempDates[selectedWBS], [id]: null }
      //     : { ...tempDates, [id]: null };
      //   tempDates[selectedWBS] = _sm;

      //   setScheduleDates(tempDates);
      //   toastr.info("Planned FW Start Date should be greater than or equal to Final Questionnaire Delivery Date");
      // } else 
      if (
        tempDates[selectedWBS].PlannedFWEnd &&
        tempDates[selectedWBS].PlannedFWEnd < e
      ) {
        // let _sm = tempDates[selectedWBS]
        //   ? { ...tempDates[selectedWBS][sm],FWEnd:null}
        //   : { ...tempDates[sm], FWStart: null, };
        // tempDates[selectedWBS] = { ...tempDates[selectedWBS], [sm]: _sm };

        // setScheduleDates(tempDates);

        toastr.info("Planned FW Start Date should be less than Planned FW End Date");
      } else {
        let tempDates = _.cloneDeep(scheduleDate);
        let temp = {
          SubmethodologyLabel: wbsNumbers.map((wb) => selectedMethodologies.map((sm, idx) => {
            if(_.includes(sm.wbsnumbers, wb.value) && (wb.value==selectedWBS)) return sm.Label})).flat()
              .filter(item => item !== undefined).join(', '),
          PlannedFWstartdate: new Date(e),
          OldPlannedFWstartdate: tempDates[selectedWBS][id],
          OldFWStartDate: tempDates[selectedWBS]?.FWStart,
          OldFWEndDate:tempDates[selectedWBS]?.FWEnd,
          OldQuestionnaireDate: tempDates[selectedWBS]?.QueDate,
          OldOPSProjectManager: tempDates[selectedWBS]?.OPSProjectManager,
          OldPlannedFWenddate: tempDates[selectedWBS]?.PlannedFWEnd,
          WBSNumber: selectedWBS,
          CostingProfileId: profile.id,
          Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
        };
        let _historyData = _.cloneDeep(historyData);
        let rec = _historyData.find(
          (wb) => wb.WBSNumber == selectedWBS
        );

        if (rec) {
          _historyData = _historyData.map((ele) => {
            if (
              ele.WBSNumber == selectedWBS &&
              ele[`Old${id}Date`]
            ) {
              return { ...ele, PlannedFWstartdate: e };
            } else if (
              ele.WBSNumber == selectedWBS &&
              !ele[`Old${id}Date`]
            ) {
              return {
                ...ele,
                PlannedFWstartdate: e,
                OldPlannedFWstartdate: tempDates[selectedWBS][id],
              };
            } else return ele;
          });
        } else {
          _historyData.push(temp);
        }

        setHistoryData(_historyData);
        console.log(_historyData);

        let _sm = tempDates[selectedWBS]
          ? { ...tempDates[selectedWBS], [id]: e }
          : { ...tempDates, [id]: e };

        tempDates[selectedWBS] = _sm ;

        setScheduleDates(tempDates);
      }
    } else {
      let _sm = tempDates[selectedWBS]
        ? { ...tempDates[selectedWBS], [id]: null }
        : { ...tempDates, [id]: null };
      tempDates[selectedWBS] = _sm;

      setScheduleDates(tempDates);
      // toastr.error("Please Select Questionnaire Date");
    }
    console.log(tempDates);
  };

  const onChangeHandlerActualFWStart = (e, id, selectedWBS) => {
    setIsActualFWStartDateTouched(true);
    setEnableOnlyOneRow([...enableonlyonerow, selectedWBS])
    setAllDates({ ...allDates, ActualFWStartDate: e })
    !(_.isEqual(acceptPM[selectedWBS].ActualFWStart,e))  ? 
      setEnableUpdate(true) : setEnableUpdate(false);
    let tempDates = _.cloneDeep(scheduleDate);

    let _historyData = _.cloneDeep(historyData);
    let rec = _historyData.find(
      (wb) => wb.WBSNumber == selectedWBS
    );
    if (rec) {
      _historyData = _historyData.map((ele) => {
        if (
          ele.WBSNumber == selectedWBS &&
          !ele.OldActualFWStartDate
        ) {
          return { ...ele, OldActualFWStartDate: tempDates[selectedWBS][id] };
        } else return ele;
      });
    } else {
      _historyData.push({
        OldActualFWStartDate: tempDates[selectedWBS][id],
        SubmethodologyLabel: wbsNumbers.map((wb) => selectedMethodologies.map((sm, idx) => {
          if(_.includes(sm.wbsnumbers, wb.value) && (wb.value==selectedWBS)) return sm.Label})).flat()
            .filter(item => item !== undefined).join(', '),
        OldPlannedFWstartdate: tempDates[selectedWBS]?.PlannedFWStart,
        OldFWStartDate: tempDates[selectedWBS]?.FWStart,
        OldFWEndDate:tempDates[selectedWBS]?.FWEnd,
        OldQuestionnaireDate: tempDates[selectedWBS]?.QueDate,
        OldOPSProjectManager: tempDates[selectedWBS]?.OPSProjectManager,
        OldPlannedFWenddate: tempDates[selectedWBS]?.PlannedFWEnd,
        WBSNumber: selectedWBS,
        CostingProfileId: profile.id,
        Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
      });
    }

    setHistoryData(_historyData);

    if (tempDates[selectedWBS] && tempDates[selectedWBS].QueDate) {
      let tempDates = _.cloneDeep(scheduleDate);
      if (e?.getTime() >= tempDates[selectedWBS].QueDate.getTime() || e?.getTime() < tempDates[selectedWBS].QueDate.getTime()) {
        // else if (
        //   tempDates[selectedWBS][sm].FWEnd &&
        //   tempDates[selectedWBS][sm].FWEnd <= e
        // ) {
        //   // let _sm = tempDates[selectedWBS]
        //   //   ? { ...tempDates[selectedWBS][sm],FWEnd:null}
        //   //   : { ...tempDates[sm], FWStart: null, };
        //   // tempDates[selectedWBS] = { ...tempDates[selectedWBS], [sm]: _sm };

        //   // setScheduleDates(tempDates);

        //   toastr.info("FW Start Date Should be less than FW End Date");
        // }
        // else {        
        let temp = {
          SubmethodologyLabel: wbsNumbers.map((wb) => selectedMethodologies.map((sm, idx) => {
            if(_.includes(sm.wbsnumbers, wb.value) && (wb.value==selectedWBS)) return sm.Label})).flat()
              .filter(item => item !== undefined).join(', '),
          ActualFWStartDate: new Date(e),
          OldActualFWStartDate: tempDates[selectedWBS][id],
          OldPlannedFWstartdate: tempDates[selectedWBS]?.PlannedFWStart,
          OldFWStartDate: tempDates[selectedWBS]?.FWStart,
          OldFWEndDate:tempDates[selectedWBS]?.FWEnd,
          OldQuestionnaireDate: tempDates[selectedWBS]?.QueDate,
          OldOPSProjectManager: tempDates[selectedWBS]?.OPSProjectManager,
          OldPlannedFWenddate: tempDates[selectedWBS]?.PlannedFWEnd,
          WBSNumber: selectedWBS,
          CostingProfileId: profile.id,
          Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
        };
        let _historyData = _.cloneDeep(historyData);
        let rec = _historyData.find(
          (wb) => wb.WBSNumber == selectedWBS
        );

        if (rec) {
          _historyData = _historyData.map((ele) => {
            if (
              ele.WBSNumber == selectedWBS &&
              ele[`Old${id}Date`]
            ) {
              return { ...ele, ActualFWStartDate: e };
            } else if (
              ele.WBSNumber == selectedWBS &&
              !ele[`Old${id}Date`]
            ) {
              return {
                ...ele,
                ActualFWStartDate: e,
                OldActualFWStartDate: tempDates[selectedWBS][id],
              };
            } else return ele;
          });
        } else {
          _historyData.push(temp);
        }

        setHistoryData(_historyData);
        console.log(_historyData);

        let _sm = tempDates[selectedWBS]
          ? { ...tempDates[selectedWBS], [id]: e }
          : { ...tempDates, [id]: e };

        tempDates[selectedWBS] = _sm;

        setScheduleDates(tempDates);
        // }
      } else {
        let _sm = tempDates[selectedWBS]
          ? { ...tempDates[selectedWBS], [id]: null }
          : { ...tempDates, [id]: null };
        tempDates[selectedWBS] = _sm ;

        setScheduleDates(tempDates);
        setIsActualFWStartDateTouched(false);
        // toastr.info(
        //   "Actual FW Start Date should be greater than or equal to Final Questionnaire Delivery Date"
        // );
      }
    } else {
      let _sm = tempDates[selectedWBS]
        ? { ...tempDates[selectedWBS], [id]: null }
        : { ...tempDates, [id]: null };
      tempDates[selectedWBS] = _sm;

      setScheduleDates(tempDates);
      // toastr.error("Please Select Questionnaire Date");
    }
    console.log(tempDates);
  };

  const onChangeHandlerNewFwEnd = (e, id, selectedWBS) => {
    setEnableOnlyOneRow([...enableonlyonerow, selectedWBS])
    setAllDates({ ...allDates, NewFWEndDate: e })
    !(_.isEqual(acceptPM[selectedWBS].NewFWEnd,e))  ? 
    setEnableUpdate(true) : setEnableUpdate(false);
    let tempDates = _.cloneDeep(scheduleDate);

    let _historyData = _.cloneDeep(historyData);
    let rec = _historyData.find(
      (wb) => wb.WBSNumber == selectedWBS
    );
    if (rec) {
      _historyData = _historyData.map((ele) => {
        if (
          ele.WBSNumber == selectedWBS &&
          !ele[`Old${id}Date`]
        ) {
          return { ...ele, OldNewFWEndDate: tempDates[selectedWBS][id] };
        } else return ele;
      });
    } else {
      _historyData.push({
        OldNewFWEndDate: tempDates[selectedWBS][id],
        OldActualFWStartDate: tempDates[selectedWBS]?.ActualFWStart,
        OldPlannedFWstartdate: tempDates[selectedWBS]?.PlannedFWStart,
        OldFWStartDate: tempDates[selectedWBS]?.FWStart,
        OldFWEndDate:tempDates[selectedWBS]?.FWEnd,
        OldQuestionnaireDate: tempDates[selectedWBS]?.QueDate,
        OldOPSProjectManager: tempDates[selectedWBS]?.OPSProjectManager,
        OldPlannedFWenddate: tempDates[selectedWBS]?.PlannedFWEnd,
        SubmethodologyLabel: wbsNumbers.map((wb) => selectedMethodologies.map((sm, idx) => {
          if(_.includes(sm.wbsnumbers, wb.value) && (wb.value==selectedWBS)) return sm.Label})).flat()
            .filter(item => item !== undefined).join(', '),
        WBSNumber: selectedWBS,
        CostingProfileId: profile.id,
        Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
      });
    }
    setHistoryData(_historyData);

    if (tempDates[selectedWBS] && tempDates[selectedWBS].ActualFWStart) {
      if (e >= tempDates[selectedWBS].ActualFWStart) {
        let tempDates = _.cloneDeep(scheduleDate);
        let temp = {
          SubmethodologyLabel: wbsNumbers.map((wb) => selectedMethodologies.map((sm, idx) => {
            if(_.includes(sm.wbsnumbers, wb.value) && (wb.value==selectedWBS)) return sm.Label})).flat()
              .filter(item => item !== undefined).join(', '),
          NewFWEndDate: new Date(e),
          OldNewFWEndDate: tempDates[selectedWBS][id],
          OldActualFWStartDate: tempDates[selectedWBS]?.ActualFWStart,
          OldPlannedFWstartdate: tempDates[selectedWBS]?.PlannedFWStart,
          OldFWStartDate: tempDates[selectedWBS]?.FWStart,
          OldFWEndDate:tempDates[selectedWBS]?.FWEnd,
          OldQuestionnaireDate: tempDates[selectedWBS]?.QueDate,
          OldOPSProjectManager: tempDates[selectedWBS]?.OPSProjectManager,
          OldPlannedFWenddate: tempDates[selectedWBS]?.PlannedFWEnd,
          WBSNumber: selectedWBS,
          CostingProfileId: profile.id,
          Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,

        };

        let _historyData = _.cloneDeep(historyData);
        let rec = _historyData.find(
          (wb) => wb.WBSNumber == selectedWBS
        );
        if (rec) {
          _historyData = _historyData.map((ele) => {
            if (
              ele.WBSNumber == selectedWBS &&
              ele[`Old${id}Date`]
            ) {
              return { ...ele, NewFWEndDate: e };
            } else if (
              ele.WBSNumber == selectedWBS &&
              !ele[`Old${id}Date`]
            ) {
              return {
                ...ele,
                NewFWEndDate: e,
                OldNewFWEndDate: tempDates[selectedWBS][id],
              };
            } else return ele;
          });
        } else {
          _historyData.push(temp);
        }

        setHistoryData(_historyData);
        console.log(_historyData);

        let _sm = tempDates[selectedWBS]
          ? { ...tempDates[selectedWBS], [id]: e }
          : { ...tempDates, [id]: e };
        tempDates[selectedWBS] = _sm;

        setScheduleDates(tempDates);
      } else {
        let _sm = tempDates[selectedWBS]
          ? { ...tempDates[selectedWBS], [id]: null }
          : { ...tempDates, [id]: null };
        tempDates[selectedWBS] = _sm;

        setScheduleDates(tempDates);
        toastr.info("New FW End Date should be greater or equal to Actual FW Start Date");
      }
    } else {
      let _sm = tempDates[selectedWBS]
        ? { ...tempDates[selectedWBS], [id]: null }
        : { ...tempDates, [id]: null };
      tempDates[selectedWBS] = _sm;

      setScheduleDates(tempDates);
      toastr.error("Please Select Actual FW Start Date");
      console.log(tempDates);
    }
  };

  const onChangeHandlerFwEnd = (e, id, selectedWBS) => {
    !_.isEqual(acceptPM?.[selectedWBS]?.FWEnd, e) 
      ? setEnableOnlyOneRow([...enableonlyonerow, selectedWBS]) : setEnableOnlyOneRow([]);
    // setEnableOnlyOneRow([...enableonlyonerow, selectedWBS])
    // && e > acceptPM?.[selectedWBS]?.FWStart
    setAllDates({ ...allDates, FWEndDate: e });
    !_.isEqual(acceptPM?.[selectedWBS]?.FWEnd, e) && e > acceptPM?.[selectedWBS]?.FWStart
      ? setEnableUpdate(true)
      : setEnableUpdate(false);
    let tempDates = _.cloneDeep(scheduleDate);

    let _historyData = _.cloneDeep(historyData);
    let rec = _historyData.find(
      (wb) => wb.WBSNumber == selectedWBS
    );
    if (rec) {
      _historyData = _historyData.map((ele) => {
        if (
          ele.WBSNumber == selectedWBS &&
          !ele[`Old${id}Date`]
        ) {
          return { ...ele, OldFWEndDate: tempDates[selectedWBS][id] };
        } else return ele;
      });
    } else {
      _historyData.push({
        OldFWEndDate: tempDates[selectedWBS][id],
        SubmethodologyLabel: wbsNumbers.map((wb) => selectedMethodologies.map((sm, idx) => {
          if(_.includes(sm.wbsnumbers, wb.value) && (wb.value==selectedWBS)) return sm.Label})).flat()
            .filter(item => item !== undefined).join(', '),
        WBSNumber: selectedWBS,
        OldFWStartDate:tempDates[selectedWBS]?.FWStart,
        OldQuestionnaireDate: tempDates[selectedWBS]?.QueDate,
        CostingProfileId: profile.id,
        Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
      });
    }
    setHistoryData(_historyData);

    if (tempDates[selectedWBS] && tempDates[selectedWBS].FWStart) {
      if (e > tempDates[selectedWBS].FWStart) {
        let tempDates = _.cloneDeep(scheduleDate);
        let temp = {
          SubmethodologyLabel: wbsNumbers.map((wb) => selectedMethodologies.map((sm, idx) => {
            if(_.includes(sm.wbsnumbers, wb.value) && (wb.value==selectedWBS)) return sm.Label})).flat()
              .filter(item => item !== undefined).join(', '),
          FWEndDate: new Date(e),
          OldFWEndDate: tempDates[selectedWBS][id],
          OldFWStartDate:tempDates[selectedWBS]?.FWStart,
          OldQuestionnaireDate: tempDates[selectedWBS]?.QueDate,
          WBSNumber: selectedWBS,
          CostingProfileId: profile.id,
          Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
        };

        let _historyData = _.cloneDeep(historyData);
        let rec = _historyData.find(
          (wb) => wb.WBSNumber == selectedWBS
        );
        if (rec) {
          _historyData = _historyData.map((ele) => {
            if (
              ele.WBSNumber == selectedWBS &&
              ele[`Old${id}Date`]
            ) {
              return { ...ele, FWEndDate: e };
            } else if (
              ele.WBSNumber == selectedWBS &&
              !ele[`Old${id}Date`]
            ) {
              return {
                ...ele,
                FWEndDate: e,
                OldFWEndDate: tempDates[selectedWBS][id],
              };
            } else return ele;
          });
        } else {
          _historyData.push(temp);
        }

        setHistoryData(_historyData);
        console.log(_historyData);

        let _sm = tempDates[selectedWBS]
          ? { ...tempDates[selectedWBS], [id]: e, PlannedFWEnd: e }
          : { ...tempDates, [id]: e, PlannedFWEnd: e };
        tempDates[selectedWBS] = _sm;

        setScheduleDates(tempDates);
      } else {
        let _sm = tempDates[selectedWBS]
          ? { ...tempDates[selectedWBS], [id]: tempDates[selectedWBS]?.FWEnd }
          : { ...tempDates, [id]: tempDates[selectedWBS]?.FWEnd };
        tempDates[selectedWBS] = _sm;

        setScheduleDates(tempDates);
        toastr.info("Initial Planned FW End Date should be greater than Initial Planned FW Start Date");
      }
    } else {
      let _sm = tempDates[selectedWBS]
        ? { ...tempDates[selectedWBS], [id]: null }
        : { ...tempDates, [id]: null };
      tempDates[selectedWBS] = _sm;

      setScheduleDates(tempDates);
      toastr.error("Please Select FW Start Date");
      console.log(tempDates);
    }
  };

  const onChangeHandlerPlannedFwEnd = (e, id, selectedWBS) => {
    setEnableOnlyOneRow([...enableonlyonerow, selectedWBS]);
    setAllDates({ ...allDates, PlannedFWenddate: e });
    !_.isEqual(acceptPM?.[selectedWBS]?.PlannedFWEnd, e)
      ? setEnableUpdate(true)
      : setEnableUpdate(false);
    let tempDates = _.cloneDeep(scheduleDate);

    let _historyData = _.cloneDeep(historyData);
    let rec = _historyData.find(
      (wb) => wb.WBSNumber == selectedWBS
    );
    if (rec) {
      _historyData = _historyData.map((ele) => {
        if (
          ele.WBSNumber == selectedWBS &&
          !ele[`Old${id}Date`]
        ) {
          return { ...ele, OldPlannedFWenddate: tempDates[selectedWBS][id] };
        } else return ele;
      });
    } else {
      _historyData.push({
        OldPlannedFWenddate: tempDates[selectedWBS][id],
        SubmethodologyLabel: wbsNumbers.map((wb) => selectedMethodologies.map((sm, idx) => {
          if(_.includes(sm.wbsnumbers, wb.value) && (wb.value==selectedWBS)) return sm.Label})).flat()
            .filter(item => item !== undefined).join(', '),
        OldPlannedFWstartdate: tempDates[selectedWBS]?.PlannedFWStart,
        OldFWStartDate: tempDates[selectedWBS]?.FWStart,
        OldFWEndDate:tempDates[selectedWBS]?.FWEnd,
        OldQuestionnaireDate: tempDates[selectedWBS]?.QueDate,
        OldOPSProjectManager: tempDates[selectedWBS]?.OPSProjectManager,
        WBSNumber: selectedWBS,
        CostingProfileId: profile.id,
        Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
      });
    }
    setHistoryData(_historyData);

    if (tempDates[selectedWBS] && tempDates[selectedWBS].PlannedFWStart) {
      if (e >= tempDates[selectedWBS].PlannedFWStart) {
        let tempDates = _.cloneDeep(scheduleDate);
        let temp = {
          SubmethodologyLabel: wbsNumbers.map((wb) => selectedMethodologies.map((sm, idx) => {
            if(_.includes(sm.wbsnumbers, wb.value) && (wb.value==selectedWBS)) return sm.Label})).flat()
              .filter(item => item !== undefined).join(', '),
          PlannedFWenddate: new Date(e),
          OldPlannedFWenddate: tempDates[selectedWBS][id],
          OldPlannedFWstartdate: tempDates[selectedWBS]?.PlannedFWStart,
          OldFWStartDate: tempDates[selectedWBS]?.FWStart,
          OldFWEndDate:tempDates[selectedWBS]?.FWEnd,
          OldQuestionnaireDate: tempDates[selectedWBS]?.QueDate,
          OldOPSProjectManager: tempDates[selectedWBS]?.OPSProjectManager,
          WBSNumber: selectedWBS,
          CostingProfileId: profile.id,
          Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
        };

        let _historyData = _.cloneDeep(historyData);
        let rec = _historyData.find(
          (wb) => wb.WBSNumber == selectedWBS
        );
        if (rec) {
          _historyData = _historyData.map((ele) => {
            if (
              ele.WBSNumber == selectedWBS &&
              ele[`Old${id}Date`]
            ) {
              return { ...ele, PlannedFWenddate: e };
            } else if (
              ele.WBSNumber == selectedWBS &&
              !ele[`Old${id}Date`]
            ) {
              return {
                ...ele,
                PlannedFWenddate: e,
                OldPlannedFWenddate: tempDates[selectedWBS][id],
              };
            } else return ele;
          });
        } else {
          _historyData.push(temp);
        }

        setHistoryData(_historyData);
        console.log(_historyData);

        let _sm = tempDates[selectedWBS]
          ? { ...tempDates[selectedWBS], [id]: e }
          : { ...tempDates, [id]: e };
        tempDates[selectedWBS] = _sm;

        setScheduleDates(tempDates);
      } else {
        let _sm = tempDates[selectedWBS]
          ? { ...tempDates[selectedWBS], [id]: tempDates[selectedWBS]?.PlannedFWEnd }
          : { ...tempDates, [id]: tempDates[selectedWBS]?.PlannedFWEnd };
        tempDates[selectedWBS] = _sm;

        setScheduleDates(tempDates);
        toastr.info("Planned FW End Date should be greater than or equal to Planned FW Start Date");
      }
    } else {
      let _sm = tempDates[selectedWBS]
        ? { ...tempDates[selectedWBS], [id]: null }
        : { ...tempDates, [id]: null };
      tempDates[selectedWBS] = _sm;

      setScheduleDates(tempDates);
      // toastr.error("Please Select FW Start Date");
      console.log(tempDates);
    }
  };

  const handleProjectManagerChange = (pm, id, selectedWBS) => {
    if(pm && pm.length > 0) {
      let tempDates = _.cloneDeep(scheduleDate);
      const projectmanager = pm?.map((i) => {
        return i.value;
      });
      let pjm = projectmanager?.join();   
      let _historyData = _.cloneDeep(historyData);
      let rec = _historyData.find(
        (wb) => wb.WBSNumber == selectedWBS
      );
      if (rec) {
        _historyData = _historyData.map((ele) => {
          if (
            ele.WBSNumber == selectedWBS &&
            !ele[`Old${id}Date`]
          ) {
            return { ...ele, OldOPSProjectManager: tempDates[selectedWBS][id] };
          } else return ele;
        });
      } else {
        _historyData.push({
          OldOPSProjectManager: tempDates[selectedWBS]?.OPSProjectManager,
          NewOPSProjectManager: pjm,
          OldPlannedFWenddate: tempDates[selectedWBS]?.PlannedFWEnd,
          OldPlannedFWstartdate: tempDates[selectedWBS]?.PlannedFWStart,
          OldFWStartDate: tempDates[selectedWBS]?.FWStart,
          OldFWEndDate:tempDates[selectedWBS]?.FWEnd,
          OldQuestionnaireDate: tempDates[selectedWBS]?.QueDate,
          OldActualFWStartDate: tempDates[selectedWBS]?.ActualFWStart,
          OldNewFWEndDate: tempDates[selectedWBS]?.NewFWEnd,
          SubmethodologyLabel: wbsNumbers.map((wb) => selectedMethodologies.map((sm, idx) => {
            if(_.includes(sm.wbsnumbers, wb.value) && (wb.value==selectedWBS)) return sm.Label})).flat()
              .filter(item => item !== undefined).join(', '),
          WBSNumber: selectedWBS,
          CostingProfileId: profile.id,
          Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
        });
      }
      setHistoryData(_historyData);
         
      let _sm = tempDates[selectedWBS]
            ? { ...tempDates[selectedWBS], [id]: pjm }
            : { ...tempDates, [id]: pjm };

      tempDates[selectedWBS] = _sm;
      setScheduleDates(tempDates);
    } else {
      toastr.info("Please add a new Project Manager before removing the old one.")
    }

    // console.log(pm, tempDates,"pjm", scheduleDate);

  };

  const saveDatesWbs = (
    updateProjectManager,
    filtered2,
    historyData,
    acceptProjectManager,
    acceptStatus,
    updateStatus
  ) => {
    let projectManager;
    projectManager = currproj.map((i) => {
      return i.value;
    });
    historyData = historyData.filter(
      (i) =>
        i.WBSNumber == wbsnumber.wbsnumber_sd 
        // && i.SubmethodologyLabel == wbsnumber.label_sd
    );
    Axios.post("costingProfiles/historyDateAdjustments", historyData, {
      headers: { "auth-token": localStorage.getItem("auth-token") },
    })
    .then((res) => {
      toastr.success("Dates History Saved");
    })
    .catch((err) => {
      console.log("Dates History Saving Failed", err);
    });
    dispatch(recordLoadStart());
    if (
      wbsNumbers
        .map((number) => number.value)
        .every((wbs) =>
          selectedMethodologies
            .filter((m) => _.includes(m.wbsnumbers, wbs))
            .every(
              (sm) =>
                scheduleDate[wbs] &&
                scheduleDate[wbs]?.QueDate &&
                scheduleDate[wbs]?.FWStart &&
                scheduleDate[wbs]?.FWEnd 
                // scheduleDate[wbs][sm.Label]?.ActualFWStart &&
                // scheduleDate[wbs][sm.Label]?.NewFWEnd
            )
        )
    ) {
      let data = [];
      Object.keys(scheduleDate).map((wb) => {
        if(wb == wbsnumber.wbsnumber_sd) {
          // Object.keys(scheduleDate[wb]).map((sd) => {
            // if(sd == wbsnumber.label_sd) {
              if(historyData && historyData.length > 0 && filtered2 && acceptStatus && !updateStatus) {
                data.push({
                  QuestionnaireDate: acceptProjectManager[wb].QueDate ? acceptProjectManager[wb].QueDate : null,
                  FWStartDate: acceptProjectManager[wb].FWStart ? acceptProjectManager[wb].FWStart : null,
                  FWEndDate: acceptProjectManager[wb].FWEnd ? acceptProjectManager[wb].FWEnd : null,
                  ActualFWStartDate: acceptProjectManager[wb]?.ActualFWStart ? acceptProjectManager[wb].ActualFWStart : null,
                  NewFWEndDate: acceptProjectManager[wb]?.NewFWEnd ? acceptProjectManager[wb].NewFWEnd : null,
                  PlannedFWstartdate: acceptProjectManager[wb]?.OPSProjectManager && acceptProjectManager[wb]?.PlannedFWStart ? acceptProjectManager[wb].PlannedFWStart : null,
                  PlannedFWenddate: acceptProjectManager[wb]?.OPSProjectManager && acceptProjectManager[wb]?.PlannedFWEnd ? acceptProjectManager[wb].PlannedFWEnd : null,
                  CostingProfileId: profile.id,
                  // "WaveNumber":"1",
                  // "WaveSpecId":currentWaveSpec.id,
                  SubmethodologyLabel: selectedMethodologies.map((sm, idx) => (
                    _.includes(sm.wbsnumbers, wb) && sm.Label)).filter(item => typeof item!== 'boolean').join(', '),
                  WBSNumber: wb,
                  Product: wbsNumbers.find((rec) => rec.value === wb)?.product
                    ? wbsNumbers.find((rec) => rec.value === wb).product
                    : "",
                  id: acceptProjectManager[wb].id,
                  OPSProjectManager: acceptProjectManager[wb].OPSProjectManager ? acceptProjectManager[wb].OPSProjectManager : '',
                  userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                })
              } else {
                  data.push({
                    QuestionnaireDate: scheduleDate[wb].QueDate ? scheduleDate[wb].QueDate : null,
                    FWStartDate: scheduleDate[wb].FWStart ? scheduleDate[wb].FWStart : null,
                    FWEndDate: scheduleDate[wb].FWEnd ? scheduleDate[wb].FWEnd : null,
                    ActualFWStartDate: scheduleDate[wb]?.ActualFWStart ? scheduleDate[wb].ActualFWStart : null,
                    NewFWEndDate: scheduleDate[wb]?.NewFWEnd ? scheduleDate[wb].NewFWEnd : null,
                    PlannedFWstartdate: scheduleDate[wb]?.OPSProjectManager && scheduleDate[wb]?.PlannedFWStart ? scheduleDate[wb].PlannedFWStart : null,
                    PlannedFWenddate: scheduleDate[wb]?.OPSProjectManager && scheduleDate[wb]?.PlannedFWEnd ? scheduleDate[wb].PlannedFWEnd : null,
                    CostingProfileId: profile.id,
                    // "WaveNumber":"1",
                    // "WaveSpecId":currentWaveSpec.id,
                    SubmethodologyLabel: selectedMethodologies.map((sm, idx) => (
                      _.includes(sm.wbsnumbers, wb) && sm.Label)).filter(item => typeof item!== 'boolean').join(', '),
                    WBSNumber: wb,
                    Product: wbsNumbers.find((rec) => rec.value === wb)?.product
                      ? wbsNumbers.find((rec) => rec.value === wb).product
                      : "",
                    id: scheduleDate[wb].id,
                    OPSProjectManager: updateProjectManager ? updateProjectManager[wb].OPSProjectManager ? updateProjectManager[wb].OPSProjectManager : '' : '',
                    userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                  })
              }
            // }
       }
      });
      Axios.post("deliveries/shcedule", data)
      .then((res) => {
        // setScheduleDate(false);
        setSavedInDB(true);
        toastr.success("Dates Adjustment Success !!");
        dispatch(recordLoadEnd());
      })
      .catch((err) => {
        toastr.error(err);
        dispatch(recordLoadEnd());
      });
    } else {
      toastr.error("Please fill all the dates");
      dispatch(recordLoadEnd());
    }
  };

  const saveDates = () => {
    Axios.post("costingProfiles/historyDateAdjustments", historyData, {
      headers: { "auth-token": localStorage.getItem("auth-token") },
    })
    .then((res) => {
      toastr.success("Dates History Saved");
    })
    .catch((err) => {
      console.log("Dates History Saving Failed", err);
    });

    dispatch(recordLoadStart());
    if (
      wbsNumbers
        .map((number) => number.value)
        .every((wbs) =>
          selectedMethodologies
            .filter((m) => _.includes(m.wbsnumbers, wbs))
            .every(
              (sm) =>
                scheduleDate[wbs] &&
                scheduleDate[wbs]?.QueDate &&
                scheduleDate[wbs]?.FWStart &&
                scheduleDate[wbs]?.FWEnd 
                // scheduleDate[wbs][sm.Label]?.ActualFWStart &&
                // scheduleDate[wbs][sm.Label]?.NewFWEnd
            )
        )
    ) {
      let data = [];
      Object.keys(scheduleDate).map((wb) =>
        // Object.keys(scheduleDate[wb]).map((sd) =>
          data.push({
            QuestionnaireDate: scheduleDate[wb].QueDate ? scheduleDate[wb].QueDate : null,
            FWStartDate: scheduleDate[wb].FWStart ? scheduleDate[wb].FWStart : null,
            FWEndDate: scheduleDate[wb].FWEnd ? scheduleDate[wb].FWEnd : null,
            ActualFWStartDate: scheduleDate[wb]?.ActualFWStart ? scheduleDate[wb].ActualFWStart : null,
            NewFWEndDate: scheduleDate[wb]?.NewFWEnd ? scheduleDate[wb].NewFWEnd : null,
            CostingProfileId: profile.id,
            PlannedFWstartdate: scheduleDate[wb]?.OPSProjectManager && scheduleDate[wb]?.PlannedFWStart ? scheduleDate[wb].PlannedFWStart : null,
            PlannedFWenddate: scheduleDate[wb]?.OPSProjectManager && scheduleDate[wb]?.PlannedFWEnd ? scheduleDate[wb].PlannedFWEnd : null,
            // "WaveNumber":"1",
            // "WaveSpecId":currentWaveSpec.id,
            SubmethodologyLabel: selectedMethodologies.map((sm, idx) => (
              _.includes(sm.wbsnumbers, wb) && sm.Label)).filter(item => typeof item!== 'boolean').join(', '),
            WBSNumber: wb,
            Product: wbsNumbers.find((rec) => rec.value === wb)?.product
              ? wbsNumbers.find((rec) => rec.value === wb).product
              : "",
            id: scheduleDate[wb].id,
            OPSProjectManager: scheduleDate[wb].OPSProjectManager
              ? scheduleDate[wb].OPSProjectManager
              : "",
            userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
          })
        // )
      );

      console.log(data);
      Axios.post("deliveries/shcedule", data)
        .then((res) => {
          setScheduleDate(false);
          setSavedInDB(true);
          setShowSaveModal(false);
          let wbs = wbsNumbers.map(wb => wb.value);
          wbs = wbs.join(', ');
          toastr.success(`Saved Success for ${wbs}`);
          dispatch(recordLoadEnd());
        })
        .catch((err) => {
          toastr.error(err);
          dispatch(recordLoadEnd());
        });
    } else {
      toastr.error("Please fill all the dates");
      dispatch(recordLoadEnd());
    }
  };

  const getWorkingDays = (date) => {
    let commCountry = codeLabels.FieldingCountriesOptions.find(
      (fc) => fc.Code == project.CommissioningCountry
    );
    let days = 4;
    let weekendFirstDay = commCountry.WeekStartDay;
    let weekendSecondDay = commCountry.WeekEndDay;
    date = moment(date); // use a clone
    if (date.isoWeekday() == weekendFirstDay) date = date.subtract(1, "days");
    if (date.isoWeekday() == weekendSecondDay) date = date.subtract(2, "days");

    while (days > 0) {
      // if (date.isoWeekday() === weekendFirstDay)
      //     date = date.subtract(3, 'days');
      // else if (date.isoWeekday() === weekendSecondDay)
      //     date = date.subtract(2, 'days');

      date = date.add(1, "days");
      // decrease "days" only if it's a weekday.
      //Dates enum
      // MONDAY: 1,
      // TUESDAY: 2,
      // WEDNESDAY: 3,
      // THURSDAY: 4,
      // FRIDAY: 5,
      // SATURDAY: 6,
      // SUNDAY: 7
      if (
        date.isoWeekday() !== weekendFirstDay &&
        date.isoWeekday() !== weekendSecondDay
      ) {
        days -= 1;
      }
    }
    return date.format("YYYY-MM-DD");
  };
  const disableDatePicker = (wbsno) => {
    let r = false;
    let c = false;
    isclosed.forEach((val, index) => {
      // console.log(val,wbsno);
      if (val.wbsnumber == wbsno) {
        r = val.state;
      }
    });
    isCancelled.forEach((val, index) => {
      if (val.wbsnumber == wbsno) {
        c = val.state;
      }
    });
    if (r == true || c == true) {
      return true;
    } else {
      return false;
    }
    // console.log(res);
  };
  // console.log(disableDatePicker("45.89.87"));
  // console.log(selectedMethodologies,"selectedMethodologies", wbsNumbers,scheduleDate)
  let isProjectManagerAssigned = false;
  let isDeskResearchSelected = false;
  wbsNumbers?.map(
    (wb) => 
    selectedMethodologies?.map((sm, idx) => {
      if(_.includes(sm.wbsnumbers, wb.value) && scheduleDate &&
      scheduleDate[wb.value] &&
      scheduleDate[wb.value]?.OPSProjectManager) {
        isProjectManagerAssigned = true;
      }
      if(_.includes(sm.wbsnumbers, wb.value) && exceptionMethodology.includes(sm.Label)) {
        isDeskResearchSelected = true;
      }
    }
    )
  );

  return (
    <div>
      {selectedMethodologies.length && (
        <Container>
          <Card className="ml-2 mr-2 mb-2">
            <CardHeader>
              <Row>
                <Col xs="9">
                  <CardTitle className="text-uppercase">
                    WBS LEVEL SCHEDULE
                  </CardTitle>
                </Col>
                <h5>
                  <Link
                    className="float-right"
                    onClick={() => {
                      fetch();
                      setHistoryOpen(true);
                    }}
                  >
                    View History
                  </Link>
                </h5>
              </Row>
            </CardHeader>
            <CardBody>
              {/* {JSON.stringify(historyDatatoDisplay)} */}
              {/* <table className="table table-stripped table-bordered table-responsive">
                <tr>
                  <th>WBS Number</th>
                  <th>Product</th>
                  <th>Methodologies</th>
                  <th>Final Questionnaire Delivery Date</th>
                  <th>Initial Planned FW Start Date</th>
                  <th>
                    Initial Planned FW End Date
                  </th>
                  <th>
                    Actions
                  </th>
                  <th>
                    Assigned Project Manager
                  </th>
                  <th>
                    Actual FW Start Date<br/>            
                  </th>
                  <th>
                    New Planned FW End Date
                  </th>
                </tr>
                {console.log(selectedMethodologies,"selectedMethodologies")}
                {selectedMethodologies.map((sm) =>
                  wbsNumbers.map(
                    (wb) =>
                      _.includes(sm.wbsnumbers, wb.value) && (
                        <tr>
                          <td>{wb.label}</td>
                          <td>{wb.product}</td>
                          <td>
                            <Label>{sm.Label}</Label>
                          </td>
                          <td>
                            {" "}
                            <DatePicker
                              // disabled={
                              //     df.DisablingVal||disableforCS
                              // }
                              // locale="en-GB"
                              disabled={(disableDatePicker(wb.value) || userRecord ? !(userRecord.IsClientService) : false) || (scheduleDate &&
                                scheduleDate[wb.value] &&
                                scheduleDate[wb.value][sm.Label]?.OPSProjectManager
                                ? true
                                : false)}
                              className="form-control"
                              selected={
                                scheduleDate &&
                                scheduleDate[wb.value] &&
                                scheduleDate[wb.value][sm.Label]?.QueDate
                                  ? scheduleDate[wb.value][sm.Label]?.QueDate
                                  : ""
                              }
                              onChange={(e) =>
                                onChangeHandlerQueDates(
                                  e,
                                  "QueDate",
                                  sm.Label,
                                  wb.value
                                )
                              }
                              dateFormat="dd-MM-yyyy"
                              placeholderText="dd-mm-yyyy"
                            />
                          </td>
                          <td>
                            <DatePicker
                              // locale="en-GB"
                              className="form-control"
                              selected={
                                scheduleDate &&
                                scheduleDate[wb.value] &&
                                scheduleDate[wb.value][sm.Label]?.FWStart
                                  ? scheduleDate[wb.value][sm.Label]?.FWStart
                                  : ""
                              }
                              disabled={disableDatePicker(wb.value) || (scheduleDate &&
                                scheduleDate[wb.value] &&
                                scheduleDate[wb.value][sm.Label]?.OPSProjectManager
                                ? true
                                : false)}
                              onChange={(e) =>
                                onChangeHandlerFWStart(
                                  e,
                                  "FWStart",
                                  sm.Label,
                                  wb.value
                                )
                              }
                              dateFormat="dd-MM-yyyy"
                              placeholderText="dd-mm-yyyy"
                            />
                          </td>
                          <td>
                            <DatePicker
                              // disabled={
                              //     df.DisablingVal||disableforCS
                              // }
                              // locale="en-GB"
                              className="form-control"
                              selected={
                                scheduleDate &&
                                scheduleDate[wb.value] &&
                                scheduleDate[wb.value][sm.Label]?.FWEnd
                                  ? scheduleDate[wb.value][sm.Label]?.FWEnd
                                  : ""
                              }
                              disabled={disableDatePicker(wb.value) || (scheduleDate &&
                                scheduleDate[wb.value] &&
                                scheduleDate[wb.value][sm.Label]?.OPSProjectManager
                                ? true
                                : false)}
                              onChange={(e) =>
                                onChangeHandlerFwEnd(
                                  e,
                                  "FWEnd",
                                  sm.Label,
                                  wb.value
                                )
                              }
                              dateFormat="dd-MM-yyyy"
                              placeholderText="dd-mm-yyyy"
                            />
                          </td>
                          <td className="d-flex justify-content-center">
                            <Button
                              className="mr-2"
                              color="primary"
                              disabled={(userRecord ? !(userRecord.IsOpsProjectManager) : false) || (scheduleDate &&
                                scheduleDate[wb.value] &&
                                scheduleDate[wb.value][sm.Label]?.OPSProjectManager
                                ? true
                                : false)}
                              onClick={() => {
                                setCurrProj([]);
                                setShowModal(true);
                                onChangeHandlerUpdate(sm.Label, wb.value);
                              }}
                            >
                              Accept
                            </Button>
                            <Button
                              disabled={(!enableonlyonerow.includes(wb.value) || !enableUpdate) || (scheduleDate &&
                                scheduleDate[wb.value] &&
                                scheduleDate[wb.value][sm.Label]?.OPSProjectManager
                                ? true
                                : false)}
                              onClick={() => {
                                setCurrProj([]);
                                setShowUpdateModal(true);
                                onChangeHandlerUpdate(sm.Label, wb.value);
                              }}
                              color="primary"
                            >
                              Update
                            </Button>
                          </td>
                          <td width="25px">
                            {scheduleDate &&
                            scheduleDate[wb.value] &&
                            scheduleDate[wb.value][sm.Label]?.OPSProjectManager
                              ? scheduleDate[wb.value][sm.Label]
                                  ?.OPSProjectManager
                              : ""}
                          </td>
                          <td className="col-6">
                            <DatePicker
                              // locale="en-GB"
                              className="form-control"
                              selected={
                                scheduleDate &&
                                  scheduleDate[wb.value] &&
                                  scheduleDate[wb.value][sm.Label]?.ActualFWStart
                                  ? scheduleDate[wb.value][sm.Label]?.ActualFWStart
                                  : ""
                              }
                              disabled={disableDatePicker(wb.value) || (scheduleDate &&
                                scheduleDate[wb.value] &&
                                scheduleDate[wb.value][sm.Label]?.OPSProjectManager
                                ? false
                                : true) || (  isActualFWStartDateSaved[wb.value] &&
                                  isActualFWStartDateSaved[wb.value][sm.Label]?.ActualFWStart
                                  ? true
                                  : false)}
                              onChange={(e) =>
                                onChangeHandlerActualFWStart(
                                  e,
                                  "ActualFWStart",
                                  sm.Label,
                                  wb.value
                                )
                              }
                              dateFormat="dd-MM-yyyy"
                              placeholderText="dd-mm-yyyy"
                            />
                          </td>
                          <td className="col-6">
                            <DatePicker
                              // locale="en-GB"
                              className="form-control"
                              selected={
                                scheduleDate &&
                                  scheduleDate[wb.value] &&
                                  scheduleDate[wb.value][sm.Label]?.NewFWEnd
                                  ? scheduleDate[wb.value][sm.Label]?.NewFWEnd
                                  : ""
                              }
                              disabled={disableDatePicker(wb.value)}
                              onChange={(e) =>
                                onChangeHandlerNewFwEnd(
                                  e,
                                  "NewFWEnd",
                                  sm.Label,
                                  wb.value
                                )
                              }
                              dateFormat="dd-MM-yyyy"
                              placeholderText="dd-mm-yyyy"
                            />
                          </td>
                        </tr>
                      )
                  )
                )}
              </table> */}
              <Table responsive bordered striped hover size="sm"> 
                <thead>
                    <tr>
                        <th className="col-3">
                            WBS Number
                        </th>
                        {wbsNumbers?.map(
                            (wb) =>
                                <th>
                                    {wb.label}
                                </th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            Product
                        </th>
                        {wbsNumbers?.map(
                            (wb) =>
                                <td>
                                    {wb.product}
                                </td>
                            )
                        }
                    </tr>
                    <tr>
                        <th>
                            Methodologies
                        </th>
                        {
                          wbsNumbers?.map(
                            (wb) =>
                            <td>
                                  {selectedMethodologies.map((sm, idx) => 
                                    _.includes(sm.wbsnumbers, wb.value) && 
                                    <>
                                      <Label>
                                        {sm.Label}                                                                                                           
                                      </Label>
                                      <br/>
                                    </>
                                  )}
                            </td>
                          )
                        }
                    </tr>
                    <tr>
                        <th>
                            Final Questionnaire Delivery Date
                        </th>
                        {wbsNumbers?.map(
                            (wb) => 
                              <td>
                                {/* {selectedMethodologies.map((sm, idx) => (                                                                   
                                _.includes(sm.wbsnumbers, wb.value) && idx == 0 &&                                  */}
                                <DatePicker
                                    // disabled={
                                    //     df.DisablingVal||disableforCS
                                    // }
                                    // locale="en-GB"
                                    disabled={disableDatePicker(wb.value) || (scheduleDate &&
                                        scheduleDate[wb.value] &&
                                        scheduleDate[wb.value]?.OPSProjectManager
                                        ? true
                                        : false)|| (handleJSONData(profile.ProductTableData).filter(w => w.WBSNumber == wb.value )[0].IsCancel)}
                                    className="form-control"
                                    selected={
                                        scheduleDate &&
                                        scheduleDate[wb.value] &&
                                        scheduleDate[wb.value]?.QueDate
                                        ? scheduleDate[wb.value]?.QueDate
                                        : ""
                                    }
                                    onChange={(e) =>
                                        onChangeHandlerQueDates(
                                        e,
                                        "QueDate",
                                        // sm.Label,
                                        wb.value
                                        )
                                    }
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="dd-mm-yyyy"
                                />
                                
                                {/* ))} */}
                              </td>
                          )}
                    </tr>
                    <tr>
                        <th>
                            Initial Planned FW Start Date
                        </th>
                        {wbsNumbers?.map(
                            (wb) => 
                              <td>
                                  {/* {selectedMethodologies.map((sm, idx) => (
                                  _.includes(sm.wbsnumbers, wb.value) && idx == 0 && */}
                                    <DatePicker
                                        // locale="en-GB"
                                        className="form-control"
                                        selected={
                                            scheduleDate &&
                                            scheduleDate[wb.value] &&
                                            scheduleDate[wb.value]?.FWStart
                                            ? scheduleDate[wb.value]?.FWStart
                                            : ""
                                        }
                                        disabled={disableDatePicker(wb.value) || (scheduleDate &&
                                            scheduleDate[wb.value] &&
                                            scheduleDate[wb.value]?.OPSProjectManager
                                            ? true
                                            : false)  || (handleJSONData(profile.ProductTableData).filter(w => w.WBSNumber == wb.value )[0].IsCancel)}
                                        onChange={(e) =>
                                            onChangeHandlerFWStart(
                                            e,
                                            "FWStart",
                                            // sm.Label,
                                            wb.value
                                            )
                                        }
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="dd-mm-yyyy"
                                    />
                                  {/* ))} */}
                              </td>
                        )}
                    </tr>
                    <tr>
                        <th>
                            Initial Planned FW End Date
                        </th>
                        {wbsNumbers?.map(
                            (wb) => 
                              <td>
                                {/* {selectedMethodologies.map((sm, idx) => (
                                _.includes(sm.wbsnumbers, wb.value) && idx == 0 && */}
                                    <DatePicker
                                        // disabled={
                                        //     df.DisablingVal||disableforCS
                                        // }
                                        // locale="en-GB"
                                        className="form-control"
                                        selected={
                                            scheduleDate &&
                                            scheduleDate[wb.value] &&
                                            scheduleDate[wb.value]?.FWEnd
                                            ? scheduleDate[wb.value]?.FWEnd
                                            : ""
                                        }
                                        disabled={disableDatePicker(wb.value) || (scheduleDate &&
                                            scheduleDate[wb.value] &&
                                            scheduleDate[wb.value]?.OPSProjectManager
                                            ? true
                                            : false) || (handleJSONData(profile.ProductTableData).filter(w => w.WBSNumber == wb.value )[0].IsCancel)}
                                        onChange={(e) =>
                                            onChangeHandlerFwEnd(
                                              e,
                                              "FWEnd",
                                              // sm.Label,
                                              wb.value
                                            )
                                        }
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="dd-mm-yyyy"
                                    />
                                  {/* ))} */}
                              </td>
                        )}
                    </tr>
                    <tr>
                        <th>
                            Actions
                        </th>
                        {wbsNumbers?.map(
                            (wb) => 
                              <td className="justify-content-start">
                                  {/* {selectedMethodologies.map((sm, idx) => (
                                  _.includes(sm.wbsnumbers, wb.value)  && idx == 0 && */}
                                  <>
                                    <Button
                                    className="mr-2"
                                    color="primary"
                                    disabled={(scheduleDate &&
                                        scheduleDate[wb.value] &&
                                        scheduleDate[wb.value]?.OPSProjectManager
                                        ? true
                                        : false) || !(!enableonlyonerow.includes(wb.value) || !enableUpdate) ||
                                        (handleJSONData(profile.ProductTableData).filter(w => w.WBSNumber == wb.value )[0].IsCancel)
                                      }
                                    onClick={() => {                                      
                                        onChangeHandlerUpdate(
                                          // sm.Label, 
                                          wb.value,
                                          "Accept"
                                        );
                                    }}
                                    >
                                    Accept
                                    </Button>
                                    <Button
                                    disabled={((!enableonlyonerow.includes(wb.value) || !enableUpdate) || (scheduleDate &&
                                        scheduleDate[wb.value] &&
                                        scheduleDate[wb.value]?.OPSProjectManager
                                        ? true
                                        : false)) || 
                                        (handleJSONData(profile.ProductTableData).filter(w => w.WBSNumber == wb.value )[0].IsCancel)
                                      }
                                    onClick={() => {                                        
                                        onChangeHandlerUpdate(
                                          // sm.Label, 
                                          wb.value,
                                          "Update"
                                        );
                                    }}
                                    color="primary"
                                    >
                                    Update
                                    </Button>
                                  </>
                                {/* ))} */}
                              </td>
                        )}
                    </tr>
                    <tr>
                        <th>
                            Assigned Project Manager
                        </th>
                        {wbsNumbers?.map(
                            (wb) => 
                              <td className="col-3">
                                  {/* {selectedMethodologies?.map((sm, idx) => (
                                    _.includes(sm.wbsnumbers, wb.value) && idx == 0 && */}
                                    
                                    {/* // scheduleDate &&
                                    //   scheduleDate[wb.value] &&
                                    //   scheduleDate[wb.value]?.OPSProjectManager
                                    //   ? scheduleDate[wb.value]?.OPSProjectManager : "" */}
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={scheduleDate &&
                                          scheduleDate[wb.value] &&
                                          scheduleDate[wb.value]?.dropDownValue?.map((item) => {
                                            return { value: item.Email, label: item.Email };
                                        })}
                                        isDisabled={(scheduleDate &&
                                          scheduleDate[wb.value] &&
                                          scheduleDate[wb.value]?.OPSProjectManager
                                          ? false : true) || currentProject.ProjectStatus === "4" || (handleJSONData(profile.ProductTableData).filter(w => w.WBSNumber == wb.value )[0].IsCancel)
                                        }
                                        isSearchable                                        
                                        value={
                                          scheduleDate && scheduleDate[wb.value] && scheduleDate[wb.value]?.OPSProjectManager 
                                          ? scheduleDate[wb.value]?.OPSProjectManager
                                            ?.split(',')
                                            ?.map(option => ({label:option, value:option}))
                                          : []
                                        
                                        }                                        
                                        onChange={(e) => {
                                            handleProjectManagerChange(
                                              e, 
                                              "OPSProjectManager", 
                                              // sm.Label, 
                                              wb.value);
                                        }}
                                        isMulti
                                        placeholder="Select Project Manager..."
                                    />
                                    
                                  {/* ))} */}
                                  {/* {console.log(scheduleDate,scheduleDate[wb.value]?.OPSProjectManager)} */}
                              </td>
                        )}
                    </tr>
                    {isProjectManagerAssigned && <tr>
                      <th>
                        Planned FW Start Date
                      </th>
                      {wbsNumbers?.map(
                              (wb) => 
                                <td>
                                  {/* {selectedMethodologies.map((sm, idx) => (
                                  _.includes(sm.wbsnumbers, wb.value) && idx == 0 && */}
                                  <DatePicker
                                      // locale="en-GB"
                                      className="form-control"
                                      selected={
                                        scheduleDate &&
                                        scheduleDate[wb.value] &&
                                        scheduleDate[wb.value]?.OPSProjectManager &&
                                        scheduleDate[wb.value]?.PlannedFWStart
                                        ? scheduleDate[wb.value]?.PlannedFWStart
                                        : ""
                                      }
                                      disabled={(disableDatePicker(wb.value) || (scheduleDate &&
                                        scheduleDate[wb.value] &&
                                        scheduleDate[wb.value]?.OPSProjectManager
                                        ? false
                                        : true) || (isActualFWStartDateSaved[wb.value] &&
                                          isActualFWStartDateSaved[wb.value]?.ActualFWStart
                                          ? true
                                          : false
                                      )) || 
                                      (handleJSONData(profile.ProductTableData).filter(w => w.WBSNumber == wb.value )[0].IsCancel)
                                    }
                                      onChange={(e) =>
                                        onChangeHandlerPlannedFWStart(
                                          e,
                                          "PlannedFWStart",
                                          // sm.Label,
                                          wb.value
                                        )
                                      }
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="dd-mm-yyyy"
                                  />
                                  
                                  {/* ))} */}
                                </td>
                      )}
                    </tr>}
                    {isProjectManagerAssigned && <tr>
                      <th>
                        Planned FW End Date
                      </th>
                      {wbsNumbers?.map(
                              (wb) => 
                                <td>
                                  {/* {selectedMethodologies.map((sm, idx) => (
                                  _.includes(sm.wbsnumbers, wb.value) && idx == 0 && */}
                                    <DatePicker
                                        // locale="en-GB"
                                        className="form-control"
                                        selected={
                                          scheduleDate &&
                                          scheduleDate[wb.value] &&
                                          scheduleDate[wb.value]?.OPSProjectManager &&
                                          scheduleDate[wb.value]?.PlannedFWEnd
                                          ? scheduleDate[wb.value]?.PlannedFWEnd
                                          : ""
                                        }
                                        disabled={(disableDatePicker(wb.value) || (scheduleDate &&
                                          scheduleDate[wb.value] &&
                                          scheduleDate[wb.value]?.OPSProjectManager
                                          ? false
                                          : true) || (isActualFWStartDateSaved[wb.value] &&
                                            isActualFWStartDateSaved[wb.value]?.ActualFWStart
                                            ? true
                                            : false
                                        )) || 
                                        (handleJSONData(profile.ProductTableData).filter(w => w.WBSNumber == wb.value )[0].IsCancel)
                                      }
                                        onChange={(e) =>
                                          onChangeHandlerPlannedFwEnd(
                                            e,
                                            "PlannedFWEnd",
                                            // sm.Label,
                                            wb.value
                                          )
                                        }
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="dd-mm-yyyy"
                                    />

                                  {/* ))} */}
                                </td>
                      )}
                    </tr>}
                    {isProjectManagerAssigned && <tr>
                        <th>
                            ACTUAL FW Start Date         
                        </th>
                        {wbsNumbers?.map(
                            (wb) => 
                              <td>
                                {/* {selectedMethodologies.map((sm, idx) => (
                                _.includes(sm.wbsnumbers, wb.value) && idx == 0 && */}
                                  <DatePicker
                                      // locale="en-GB"
                                      maxDate={new Date()}
                                      className="form-control"
                                      selected={
                                          scheduleDate &&
                                          scheduleDate[wb.value] &&
                                          scheduleDate[wb.value]?.ActualFWStart
                                          ? scheduleDate[wb.value]?.ActualFWStart
                                          : ""
                                      }
                                      disabled={(disableDatePicker(wb.value) || (isActualFWStartDateSaved[wb.value] &&
                                          isActualFWStartDateSaved[wb.value]?.ActualFWStart
                                          ? true
                                          : false) || (scheduleDate &&
                                            scheduleDate[wb.value] &&
                                            scheduleDate[wb.value]?.OPSProjectManager
                                            ? false
                                            : true)) ||
                                            (handleJSONData(profile.ProductTableData).filter(w => w.WBSNumber == wb.value )[0].IsCancel)
                                          }
                                      onChange={(e) =>
                                          onChangeHandlerActualFWStart(
                                          e,
                                          "ActualFWStart",
                                          // sm.Label,
                                          wb.value
                                          )
                                      }
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="dd-mm-yyyy"
                                  />
                                {/* ))} */}
                              </td>
                        )}
                    </tr>}
                    {isProjectManagerAssigned && <tr>
                        <th>
                            New PLANNED FW End Date
                        </th>
                        {wbsNumbers?.map(
                            (wb) => 
                              <td>
                                {/* {selectedMethodologies.map((sm, idx) => (
                                _.includes(sm.wbsnumbers, wb.value) && idx == 0 && */}
                                  <DatePicker
                                      // locale="en-GB"
                                      className="form-control"
                                      selected={
                                          scheduleDate &&
                                          scheduleDate[wb.value] &&
                                          scheduleDate[wb.value]?.NewFWEnd
                                          ? scheduleDate[wb.value]?.NewFWEnd
                                          : ""
                                      }
                                      disabled={(disableDatePicker(wb.value) || (scheduleDate &&
                                        scheduleDate[wb.value] &&
                                        scheduleDate[wb.value]?.OPSProjectManager
                                        ? false
                                        : true) || (isActualFWStartDateSaved[wb.value] &&
                                          isActualFWStartDateSaved[wb.value]?.ActualFWStart
                                          ? false
                                          : true
                                      )) ||
                                      (handleJSONData(profile.ProductTableData).filter(w => w.WBSNumber == wb.value )[0].IsCancel)
                                    }
                                      onChange={(e) =>
                                          onChangeHandlerNewFwEnd(
                                          e,
                                          "NewFWEnd",
                                          // sm.Label,
                                          wb.value
                                          )
                                      }
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="dd-mm-yyyy"
                                  />
                                {/* ))} */}
                              </td>
                        )}
                    </tr>}
                </tbody>
              </Table>
            </CardBody>
          </Card>
          <span style={{color:"red", fontWeight:"bold", fontSize:"20px"}}>
            Please update accurately
          </span>
          <Modal
            isOpen={isHistoryOpen}
            toggle={() => setHistoryOpen(false)}
            className="modal-xl"
            centered={true}
          >
            <ModalHeader>
              {/* <img alt="Nielsen Logo" src={logo} className="modallogo" /> */}
              <div>
                <span className="modaltitle modalops">
                  Date Adjustment History
                </span>
              </div>
            </ModalHeader>
            <ModalBody>
              {historyDatatoDisplay.length ? (
                <div
                  style={{
                    maxWidth: "inherit",
                    maxHeight: "400px",
                    overflow: "auto",
                    overflowX: "auto",
                  }}
                >
                  <table className="table table-stripped table-bordered">
                    <tr>
                      <th>WBS Number</th>
                      <th>Product</th>
                      <th>Methodology</th>
                      {/* <th>Old QuestionnaireDate</th> */}
                      <th>Final Questionnaire Delivery Date​</th>
                      {/* <th>Old FWStartDate</th> */}
                      <th>Initial Planned FW Start Date​</th>
                      {/* <th>Old FWEndDate</th> */}
                      <th>Initial Planned FW End Date​</th>
                      <th>Assigned Project Manager​​</th>
                      <th>Planned FW Start Date ​​​</th>
                      <th>Planned FW End Date ​​​</th>
                      <th>ACTUAL FW Start Date​​</th>
                      <th>New PLANNED FW End Date​​​</th>
                      <th>Updated By</th>
                      <th>Updated At</th>
                    </tr>

                    {historyDatatoDisplay.map((hisData) => (
                      <tr>
                        <td>{hisData.WBSNumber}</td>
                        <td>{hisData.Product ? hisData.Product : ""}</td>
                        <td>{hisData.SubmethodologyLabel}</td>
                        {/* <td>
                          {hisData.OldQuestionnaireDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.OldQuestionnaireDate)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td> */}
                        <td>
                          {hisData.QuestionnaireDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.QuestionnaireDate)}
                            ></DatePicker>
                          ) : (hisData.OldQuestionnaireDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.OldQuestionnaireDate)}
                            ></DatePicker>
                          ) : "")}
                        </td>
                        {/* <td>
                          {hisData.OldFWStartDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.OldFWStartDate)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td> */}
                        <td>
                          {hisData.FWStartDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.FWStartDate)}
                            ></DatePicker>
                          ) : (hisData.OldFWStartDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.OldFWStartDate)}
                            ></DatePicker>
                          ) : "")}
                        </td>
                        {/* <td>
                          {hisData.OldFWEndDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.OldFWEndDate)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td> */}
                        <td>
                          {hisData.FWEndDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.FWEndDate)}
                            ></DatePicker>
                          ) : (hisData.OldFWEndDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.OldFWEndDate)}
                            ></DatePicker>
                          ) : "")}
                        </td>
                        <td>
                          { 
                            (hisData?.OldOPSProjectManager ? hisData?.OldOPSProjectManager : "")
                          }
                        </td>
                        <td>
                          {hisData.PlannedFWstartdate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.PlannedFWstartdate)}
                            ></DatePicker>
                          ) : (hisData.OldPlannedFWstartdate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.OldPlannedFWstartdate)}
                            ></DatePicker>
                          ) : "")}
                        </td>
                        <td>
                          {hisData.PlannedFWenddate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.PlannedFWenddate)}
                            ></DatePicker>
                          ) : (hisData.OldPlannedFWenddate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.OldPlannedFWenddate)}
                            ></DatePicker>
                          ) : "")}
                        </td>
                        <td>
                          {hisData.ActualFWStartDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.ActualFWStartDate)}
                            ></DatePicker>
                          ) : (hisData.OldActualFWStartDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.OldActualFWStartDate)}
                            ></DatePicker>
                          ) : "")}
                        </td>
                        <td>
                          {hisData.NewFWEndDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.NewFWEndDate)}
                            ></DatePicker>
                          ) : (hisData.OldNewFWEndDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.OldNewFWEndDate)}
                            ></DatePicker>
                          ) : "")}
                        </td>                        

                        <td>{hisData.UpdatedBY ? hisData.UpdatedBY : ""}</td>
                        <td>
                          {hisData.UpdatedAT ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.UpdatedAT)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                    {wbsNumbers?.map(
                      (wb) => 
                        <tr>
                          <td>
                            {wb.label}
                          </td>
                          <td>
                            {wb.product}
                          </td>
                          <td>
                            {selectedMethodologies.map((sm, idx) => 
                              _.includes(sm.wbsnumbers, wb.value) && 
                              // <>
                              //   <Label>
                                  sm.Label                                                                                                          
                                // </Label>
                              // </>
                            ).flat().filter(item => item !== undefined).filter(item => typeof item!== 'boolean').join(', ')}
                          </td>
                          <td>
                            <DatePicker
                              disabled={true}
                              selected={new Date(scheduleDate[wb.value]?.QueDate)}
                            ></DatePicker>
                          </td>
                          <td>
                            <DatePicker
                              disabled={true}
                              selected={new Date(scheduleDate[wb.value]?.FWStart)}
                            ></DatePicker>
                          </td>
                          <td>
                            <DatePicker
                              disabled={true}
                              selected={new Date(scheduleDate[wb.value]?.FWEnd)}
                            ></DatePicker>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>

                    )}
                    
                  </table>
                </div>
              ) : (
                <div>
                  <span style={{fontWeight:"bold", fontSize:"20px"}}>
                    No history to show                    
                  </span>
                </div>
              )}
            </ModalBody>
          </Modal>
          <Button
            onClick={() => {
              setScheduleDate(false);
            }}
            className="float-right"
            color="primary"
          >
            Close
          </Button>

          <Button
            className="float-right mr-2"
            color="primary"
            disabled={!isProjectManagerAssigned || currentProject.ProjectStatus === "4"}
            onClick={() => isActualFWStartDateTouched ? setShowSaveModal(true) : saveDates()}
          >
            {recordloading ? (
              <FontAwesomeIcon
                class="saving-btn icon-costingprofile"
                icon={faSpinner}
                fixedWidth
              />
            ) : null}
            Save
          </Button>
          <Modal
            isOpen={showModal}
            toggle={() => setShowModal(false)}
            className="modal-md"
            centerd={true}
          >
            <ModalHeader toggle={() => setShowModal(!showModal)}>
              {/* <img src={logo} className="modallogo" /> */}
            </ModalHeader>

            <ModalBody>
              <Row>
                <Col>
                  <div>
                    <span className="modaltitle modaltitlemanager">
                      Select Project Manager

                    </span>                    

                  </div>
                </Col>
              </Row>

              <Select
                className="react-select-container mb-3 lg-12 md-12"
                classNamePrefix="react-select"
                options={dropdownValue?.map((item) => {
                    return { value: item.Email, label: item.Email };
                })}
                isSearchable
                value={currproj?.length ? currproj : []}
                onChange={(e) => {
                  setCurrProj(e);
                }}
                isMulti
              />

                  <span style={{color:"red", fontWeight:"normal"}}>
                    * Please note that once the dates are updated and accepted, the dates cannot be editable anymore.
                  </span>



            </ModalBody>

            <ModalFooter>
              <Row className="justify-content-end">
                <Button
                  color="primary"
                  disabled={currproj?.length == 0 || currproj == null}
                  onClick={() => saveProjectManager(true, false)}
                >
                  Save
                  {/* {app.recordloading ? (

<Spinner size="small" color="#495057" />

) : null} */}
                </Button>

                <Button
                  className="ml-2"
                  color="secondary"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
              </Row>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={showUpdateModal}
            toggle={() => setShowUpdateModal(false)}
            className="modal-md"
            centerd={true}
          >
            <ModalHeader toggle={() => setShowUpdateModal(!showUpdateModal)}>
              {/* <img src={logo} className="modallogo" /> */}
            </ModalHeader>

            <ModalBody>
              <Label className={inputValue ? "" : "text-danger"}>
                Reason for Update:<sup>*</sup>
              </Label>

              <Input
                type="text"
                placeholder=""
                required
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
              />

              <Row>
                <Col>
                  <div>
                    <span className="modaltitle modaltitlemanager">
                      Select Project Manager
                    </span>
                  </div>
                </Col>
              </Row>

              <Select
                className="react-select-container mb-3 lg-12 md-12"
                classNamePrefix="react-select"
                options={dropdownValue?.map((item) => {
                    return { value: item.Email, label: item.Email };
                })}
                isSearchable
                value={currproj?.length ? currproj : []}
                onChange={(e) => {
                  setCurrProj(e);
                }}
                isMulti
              />

                <span style={{color:"red", fontWeight:"normal"}}>
                  * Please note that once the dates are updated and accepted, the dates cannot be editable anymore.
                </span>



            </ModalBody>

            <ModalFooter>
              <Row className="justify-content-end">
                <Button
                  color="primary"
                  disabled={
                    currproj?.length == 0 ||
                    currproj == null ||
                    inputValue == ""
                  }
                  onClick={() => saveProjectManager(false, true)}
                >
                  Update
                  {/* {app.recordloading ? (

        <Spinner size="small" color="#495057" />

      ) : null} */}
                </Button>

                <Button
                  className="ml-2"
                  color="secondary"
                  onClick={() => {
                    setShowUpdateModal(false);

                    // setEnableUpdate(false);
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </ModalFooter>
          </Modal>

          <Modal

            isOpen={showSaveModal}

            toggle={() => setShowSaveModal(false)}

            className="modal-md"

            centered={true}

          >

            <ModalHeader

              toggle={() => setShowSaveModal(!showSaveModal)}

            >

              {/* <img src={logo} className="modallogo" /> */}



            </ModalHeader>



            <ModalBody>            
              <span style={{color:"red", fontWeight:"bold"}}>
                Please double-check the ACTUAL FW Start Date.<br/> Once confirmed, it cannot be edited.
                <br/><br/>
                (Date format as DD-MM-YYYY)
              </span>
            </ModalBody>

            <ModalFooter>

              <Row className="justify-content-end">

                <Button

                  color="danger"                  

                  onClick={() => saveDates()}

                >

                  Confirm                  

                </Button>

                <Button

                  className="ml-2"

                  color="primary"

                  onClick={() => {

                    setShowSaveModal(false);

                    setEnableUpdate(false)
                  }}

                >


                  Cancel

                </Button>

              </Row>

            </ModalFooter>

          </Modal>


        </Container>
      )}
    </div>
  );
}

export default ProjectScheduleWBSLevel;