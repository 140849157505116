import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as currentCostingActions from "../../../redux/actions/currentCostingActions";
import DatePicker from "react-datepicker";

import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import * as projectActions from "../../../redux/actions/projectsActions";
import DashboardLayout from "../../../layouts/Dashboard";
import { getLabel } from "../../../utils/codeLabels";
import * as appActions from "../../../redux/actions/appActions";
import logo from "../../../assets/img/avatars/modal-logo.png";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Input,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as sheetCall from "../../../utils/msSheetAPiCall";

import { MinusCircle, PlusCircle } from "react-feather";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faCommentDollar,
  faAddressCard,
  faUserPlus,
  faFilePdf,
  faTrash,
  faCopy,
  faCalendarAlt,
  faArchive,
  faFileInvoiceDollar,
  faCommentDots,
  faComment,
  faFolder,
  faInfoCircle,
  faRedoAlt,
  faThumbsUp,
  faThumbsDown,
  faHourglassHalf,
  faExclamationCircle,
  faCogs,
  faRecycle,
  faClone,
  faClipboardCheck,
  faPenSquare,
  faPencilRuler,
  faFileExcel,
  faCalendarPlus,
} from "@fortawesome/free-solid-svg-icons";
import { faFolder as faFolderO } from "@fortawesome/free-regular-svg-icons";
import _ from "lodash";
import moment from "moment";
import Spinner from "../../../components/Spinner";
import {
  pageLoadEnd,
  pageLoadStart,
  recordLoadEnd,
  recordLoadStart,
} from "../../../redux/actions/appActions";
import { toastr } from "react-redux-toastr";
import { getCosting } from "../../../redux/actions/currentCostingActions";
import ModalFooter from "reactstrap/lib/ModalFooter";
import Axios from "../../../axios-interceptor";
import { setProjects } from "../../../redux/actions/projectsActions";
import TotalOPSOOPModal from "../../../components/TotalOPSOOPModal";
import UploadedProjectModal from "../../../components/UploadedProjectModal";
import Edit from "../../../assets/icons/Connected-System-Icons/Tools/Edit-fill";
import Folderfill from "../../../assets/icons/Connected-System-Icons/Objects/Folder-fill";
import Folder from "../../../assets/icons/Connected-System-Icons/Objects/Folder";
import Copy from "../../../assets/icons/Connected-System-Icons/Tools/Copy";
import Account from "../../../assets/icons/NIQ Icons SVG/Account";
import Calendar from "../../../assets/icons/Connected-System-Icons/Data-&-Math/Calendar";
import Open from "../../../assets/icons/Connected-System-Icons/Navigation/Open";
import Trash from "../../../assets/icons/Connected-System-Icons/Objects/Trash";
import EditFill from "../../../assets/icons/Connected-System-Icons/Tools/Edit-fill";
import Info from "../../../assets/icons/Connected-System-Icons/Navigation/Info";
import useAnalyticsEventTracker from "../../../utils/useAnalyticsEventTracker";
import * as EventName from "../../../utils/GoogleAnalyticsEvents";
import SelectWrapper from "../../../components/SelectWrapper/SelectWrapper";
// import * as projectsActions from "../../../redux/actions/projectsActions";
import { COLORS } from "../../../theme";
import ProjectScheduleNew from "../../projectSchedule/ProjectScheduleNew";
import ProjectScheduleAdjustment from "../../projectSchedule/ProjectScheduleAdjustment";
import ProjectScheduleAdjustmentEWN from "../../projectSchedule/ProjectScheduleAdjustmentEWN";
import ProjectScheduleWBSLevel from "../../projectSchedule/ProjectScheduleWBSLevel";

var imgStyle = {
  width: "150px",
};
let rowData = [];
const SchedulesData = (props) => {

  const dispatch=useDispatch()
  const [WBSNumberList,setWBSNumberList]=useState([])
  const [data,setData]=useState([])
  const [rowData,setRowData]=useState({})
  const [selectedWBS,setSelectedWBS]=useState({})
  const [current, setcurrent] = useState([]);
  


  useEffect(()=>{
    if(props.profile.ScheduleDates)
    {
      let WBSarr=props.profile.ScheduleDates.map((item)=> {
        return {value:item.WBSNumber,label:item.WBSNumber}
      })
      setData(props.profile.ScheduleDates)
      setWBSNumberList(WBSarr)
      setSelectedWBS(WBSarr[0])
      setRowData(props.profile.ScheduleDates[0])

      let filterWave=props.wavespecs.filter((wave)=>wave.ProjectBoxId)
      if(filterWave.length)
      {
        setcurrent(filterWave[0])
      }
      else
      {
        setcurrent(_.head(props.wavespecs))
      }
    }
  },[])
  

  const handleChange=(e)=>{
    setSelectedWBS(e)
    let filterArr=data.filter((item)=>item.WBSNumber===e.label)
    setRowData(filterArr[0])
  }



  return (
    <div>
      <Row>
        <Col>
          {
            <Row className="justify-content-center text-center">
              <Col>
                <Row>
                  <Col>Commissioned in system : </Col>
                  {
                    new Date(props.profile.CommissionedDate)
                      .toISOString()
                      .split("T")[0]
                  }{" "}
                </Row>
              </Col>
            </Row>
          }
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Timeline</h5>
        </Col>
      </Row>
       <Row>

            {current.ProjectBoxId ? (
              <Col>
                <a
                  // href={`https://docs.google.com/spreadsheets/d/${current.ProjectBoxId}`}
                  // target="_blank"
                  className={
                    props.PriceChange == 4 ? "no-actions" : "small ewn-links"
                  }
                  onClick={() => {
                    dispatch(
                      sheetCall.msSheetAPiCall(current.ProjectBoxId, true)
                    );
                  }}
                >
                  Open Project Box
                </a>
              </Col>
            ) : null}
      </Row>
      <Row>
        <Col>
          <h5>WBS Level</h5>
        </Col>
        <Col>
            <Select
              options={WBSNumberList}
              value={selectedWBS}
              onChange={handleChange}
             />
        </Col>
      </Row>
      <Row className="mb-2">
            <Col>Product</Col>
            <Col>
                {rowData?.Product}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>Methodologies</Col>
            <Col>
              {rowData?.SubmethodologyLabel?
                rowData?.SubmethodologyLabel.split(",").map((item)=><p className="mb-1">{item}</p>)
              :null}
            </Col>
          </Row>
          {rowData?.QuestionnaireDate&&<Row className="mb-2">
            <Col>Final Questionnaire Delivery Date</Col>
            <Col>
              {rowData?.QuestionnaireDate?rowData?.QuestionnaireDate.substring(0,10):null}
            </Col>
          </Row>}
          {rowData?.FWStartDate&&<Row className="mb-2">
            <Col>Initial Planned FW Start Date</Col>
            <Col>
              {rowData?.FWStartDate?rowData?.FWStartDate.substring(0,10):null}
            </Col>
          </Row>}
          {rowData?.FWEndDate&&<Row className="mb-2">
            <Col>Initial Planned FW End Date</Col>
            <Col>
              {rowData?.FWEndDate?rowData?.FWEndDate.substring(0,10):null}
            </Col>
          </Row>}
          {rowData?.OPSProjectManager&&<Row className="mb-2">
            <Col>Assigned Project Manager</Col>
            <Col>
              {rowData?.OPSProjectManager}
            </Col>
          </Row>}
          {rowData?.PlannedFWstartdate&&<Row className="mb-2">
            <Col>Planned FW Start Date</Col>
            <Col>
              {rowData?.PlannedFWstartdate?rowData?.PlannedFWstartdate.substring(0,10):null}
            </Col>
          </Row>}
          {rowData?.PlannedFWenddate&&<Row className="mb-2">
            <Col>Planned FW End Date</Col>
            <Col>
              {rowData?.PlannedFWenddate?rowData?.PlannedFWenddate.substring(0,10):null}
            </Col>
          </Row>}
          {rowData?.ActualFWStartDate&&<Row className="mb-2">
            <Col>ACTUAL FW Start Date</Col>
            <Col>
              {rowData?.ActualFWStartDate?rowData?.ActualFWStartDate.substring(0,10):null}
            </Col>
          </Row>}
          {rowData?.NewFWEndDate&&<Row className="mb-2">
            <Col>New PLANNED FW End Date</Col>{" "}
            <Col>
              {rowData?.NewFWEndDate?rowData?.NewFWEndDate.substring(0,10):null}
            </Col>
          </Row>}
    </div>
      
  );
};

const ExpandableRowsTable = ({
  tableData,
  tableColumns,
  projectManagersList,
  projectListFilter,
  currentfilter,
}) => {
  tableData = tableData.filter((item) => item.ProjectStatus !== "5");
  const gaEventTracker = useAnalyticsEventTracker(EventName.Dashboard);
  const dispatch = useDispatch();
  const history = useHistory();
  const app = useSelector(({ app }) => app);
  const userRecord = useSelector(({ user }) => user.userRecord);
  const projects = useSelector(({ projects }) => projects.items);
  const [projecUpload, setProjectUpload] = useState(true);
  const [uploadProject, setUploadProject] = useState(false);
  const [isLocked, setLocked] = useState(false);
  const [isScheduleDates, setScheduleDate] = useState(false);

  const [selectedProject, setSelectedProject] = useState({
    projectName: "",
    projectId: "",
    PriceChange: "",
  });

  const copyLockedProject = (projectId, name, PriceChange) => {
    setSelectedProject({
      projectName: name,
      projectId: projectId,
      PriceChange: PriceChange,
    });
    setLocked(!isLocked);
  };

  const newCopiedProjectCreated = useSelector(
    ({ currentProject }) => currentProject.newProject.copiedProject
  );
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  const currentContactLoading = useSelector(
    ({ currentProject }) => currentProject.currentContactLoading
  );
  const [delUser, setDelUser] = useState({
    value: userRecord.EmailId,
    label: userRecord.EmailId,
  });
  // const [delDate, setDelDate] = useState(new Date());
  const [delReason, setDelReason] = useState("");
  const [currentProject, setCurrentProject] = useState({}); // useSelector(({ currentProject }) => currentProject.newProject);
  const [deleteProject, setDeleteProject] = useState(false);
  const [confirmDeleteModal, setConfrimDeleteModal] = useState(false);
  const [showContractDetails, setShowContractDetails] = useState(false);
  const [copiedPopUp, setCopiedPopUp] = useState(newCopiedProjectCreated);
  const [copiedProject, setCopiedProject] = useState({});
  const [opsOpp, setOpsOpp] = useState(false);
  const [clickedRow, setClickedRow] = useState();
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  let pacificData = _.head(codeLabels.PacificCountriesList)?.Label;
  let arr = pacificData?.split(",") || [];
  const [pacificMarkets] = useState(arr);
  const [expandedRowId, setExpandedRowId] = useState(null);
  const searchCharactors =
    useSelector(({ navbar }) => navbar.SearchCharactors) || "";
  const searchBy = useSelector(({ navbar }) => navbar.SearchBy) || "";
  const getSearchBy = () => {
    let searchType = searchBy;
    switch (searchType) {
      case "ID":
        searchType = "projectId";
        break;
      case "NAME":
        searchType = "projectName";
        break;
      case "OP":
        searchType = "opNumber";
        break;
      case "PO":
        searchType = "proposalOwner";
        break;
      case "AN":
        searchType = "accountName";
        break;
      case "PM":
        searchType = "projectmanager";
        break;
      default:
        searchType = "projectId";
        break;
    }
    return searchType;
  };

  const saveProjectManager = () => {
    dispatch(recordLoadStart());
    Axios.put(`/projects/${currentProject.ProjectId}/UpdateProjectParams`, {
      ProjectManagerEmail: currentProject.ProjectManagerEmail,
    })
      .then((res) => {
        dispatch(recordLoadEnd());
        //todo: sendemail
        //use SendProjectAssignEmail
        Axios.post(
          `/utils/mail/${currentProject.ProjectId}/SendProjectAssignEmail`,
          { ProjectManagerEmail: currentProject.ProjectManagerEmail }
        )
          .then((res) => {
            toastr.success(
              "Mail Sent Successfully",
              "Mail Sent to Selected Project Managers"
            );
          })
          .catch((error) => {
            toastr.error(
              "Mail Sending failed",
              "Mail Sending to Selected Project Managers failed"
            );
          });
        setShowContractDetails(false);
        let existingProjects = projects.map((it) => {
          if (it.id == currentProject.id) {
            return { ...currentProject };
          } else return { ...it };
        });
        dispatch(setProjects({ items: [...existingProjects] }));

        toastr.success(
          "Successfully Updated",
          "Assigning Project Manager Successful"
        );
      })
      .catch((error) => {
        setShowContractDetails(false);
        dispatch(recordLoadEnd());
        toastr.error(
          "Something went wrong",
          "Assigning Project Manager Failed"
        );
      });
  };
  const canRenderReview = (project) => {
    // if (Object.keys(currentProject.ProjectManagerEmail).length)
    return (
      project.ProposalOwnerEmail?.value?.toLowerCase() ==
        userRecord.Email?.toLowerCase() ||
      userRecord.ProjectReviewAccess ||
      _.includes(
        project.ProjectManagerEmail?.map((pm) => pm?.value?.toLowerCase()),
        userRecord.Email?.toLowerCase()
      )
    );
  };

  const filterManagerList = () => {
    let filterData = [];
    projectListFilter.map((item) => {
      if (item.isDisabled != 1) {
        filterData.push({ value: item.Email, label: item.Email });
      }
    });
    return filterData;
  };

  const getTotalWaves = (finalProfile) => {
    let WaveSpecs = finalProfile.WaveSpecs.filter(
      (ws) => ws.NotApplicable != true
    );
    let groupedWaves = _.groupBy(WaveSpecs, (w) => w.MethodologySpecId);
    let numberOfWaves = _.max(
      Object.keys(groupedWaves).map((gw) => groupedWaves[gw]?.length)
    );
    return numberOfWaves;
  };

  const validateProject = () => {
    let isValid = true;

    // Project Deletion Reason
    if (delReason === "") {
      isValid = false;
    }
    if (!isValid) {
      toastr.error("Validation Errors", "Please check for missing Inputs");
    }
    return isValid;
  };
  const handleDeleteProject = (row) => {
    {
      let deleteInfo = [
        {
          deletedBy: delUser?.value,
          deletedReason: delReason,
          deletedAt: moment().format("MM-DD-YYYY"),
          projectDeletedBy: userRecord.EmailId,
        },
      ];
      Axios.put("projects/delete/" + rowData[0].ProjectId, { deleteInfo }).then(
        (res) => {
          toastr.success(res.data.message);
          dispatch(projectActions.getProjects({ myProject: true }));
        }
      );
    }
  };
  const projectDetails = (row) => {
    let finalProfile = null;
    const validStatus = ["3", "9", "4", "5", "98", "11"];
    if (_.includes(validStatus, row.ProjectStatus))
      finalProfile = _.head(
        row.CostingProfiles.filter((cp) => cp.ProfileStatus == "6")
      );
    return (
      <Col
        lg={!userRecord.AllActionsAccess ? "4" : "3"}
        md={!userRecord.AllActionsAccess ? "4" : "3"}
        sm="12"
        xs="12"
        className="border-right"
      >
        <Row className="mb-2">
          <Col>
            <h5>Project Details</h5>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs="7">Office</Col>
          <Col>{getLabel("OfficeOptions", row.CommissioningOffice)}</Col>
        </Row>
        <Row className="mb-2">
          <Col xs="7">Business Unit</Col>
          <Col xs="5">{getLabel("BusinessUnitOptions", row.BusinessUnit)}</Col>
        </Row>
        <Row className="mb-2">
          <Col xs="7">Vertical</Col>
          <Col>{getLabel("VerticalOptions", row.IndustryVertical)}</Col>
        </Row>
        <Row className="mb-2">
          <Col xs="7">Syndicated Project</Col>
          <Col>{row.IsSyndicated ? "Yes" : "No"}</Col>
        </Row>
        <Row className="mb-2">
          <Col xs="7">Tracker</Col>
          <Col>
            {finalProfile ? (finalProfile.IsTracker ? "Yes" : "No") : "-"}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs="7">Total Waves</Col>
          <Col>{finalProfile ? getTotalWaves(finalProfile) : "-"}</Col>
        </Row>
        <Row className="mb-2">
          <Col xs="7">Tracking Frequency</Col>
          <Col>
            {finalProfile && finalProfile.TrackingFrequency
              ? // getLabel(
                //  "TrackingFrequencyOptions",
                finalProfile.TrackingFrequency
              : // )
                "-"}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs="7">Fielding Countries</Col>
          <Col>
            {finalProfile && finalProfile.FieldingCountries
              ? finalProfile.FieldingCountries.split(",").map(
                  (country, index) => {
                    return (
                      <li key={index} style={{ listStyle: "none" }}>
                        {getLabel("FieldingCountriesOptions", country)}
                      </li>
                    );
                  }
                )
              : "-"}
          </Col>
        </Row>
        {/* <Row>
          <Col xs="7">Methodologies</Col>
          <Col xs="5">
            <ul style={{ paddingInlineStart: "0" }}>
              {finalProfile && finalProfile.Methodology
                ? finalProfile.Methodology.split(",").map((method, index) => {
                    return (
                      <li key={index} style={{ listStyle: "none" }}>
                        {getLabel("MethodologyOptions", method)}
                      </li>
                    );
                  })
                : null}
            </ul>
          </Col>
        </Row>  */}
        <Row>
          <Col xs="7">Sub-Methodologies</Col>
          <Col xs="5">
            <ul style={{ paddingInlineStart: "0" }}>
              {finalProfile && finalProfile.SubMethodology
                ? finalProfile.SubMethodology.split(",").map(
                    (method, index) => {
                      return (
                        <li key={index} style={{ listStyle: "none" }}>
                          {getLabel("SubMethodologyOptions", method)}
                        </li>
                      );
                    }
                  )
                : "-"}
            </ul>
          </Col>
        </Row>
        <Row>
          <Col xs="7">Other Project Team Contacts</Col>
          <Col xs="5">
            {row.OtherProjectTeamContacts && row.OtherProjectTeamContacts.length
              ? row.OtherProjectTeamContacts.map(
                  (opt, index) =>
                    opt.value && (
                      <li className="no-list-style" key={index}>
                        {opt.value
                          .toLowerCase()
                          .split("@")[0]
                          .split(".")
                          .map((word) => {
                            return word.replace(word[0], word[0].toUpperCase());
                          })
                          .join(" ")}
                      </li>
                    )
                )
              : "Not Available"}
          </Col>
        </Row>
        {row?.DeletedBy && (
          <Row className="mb-2">
            <Col xs="7">Deletion requester:</Col>
            <Col xs="5">{row?.DeletedBy ? row.DeletedBy : "Not Available"}</Col>
          </Row>
        )}
        {row?.DeletedAt && (
          <Row className="mb-2">
            <Col xs="7">Deleted Date:</Col>
            <Col xs="5">
              {row?.DeletedAt
                ? moment(row.DeletedAt).format("YYYY-MM-DD")
                : "Not Available"}
            </Col>
          </Row>
        )}
        {row?.DeletedReason && (
          <Row className="mb-2">
            <Col xs="7">Deleted Reason:</Col>
            <Col xs="5">
              {row?.DeletedReason ? row.DeletedReason : "Not Available"}
            </Col>
          </Row>
        )}
        {row?.ProjectDeletedBy && (
          <Row className="mb-2">
            <Col xs="7">Project Deleted By:</Col>
            <Col xs="5">
              {row?.ProjectDeletedBy ? row.ProjectDeletedBy : "Not Available"}
            </Col>
          </Row>
        )}
      </Col>
    );
  };

  const salesforceColumn = (row) => {
    const syncClientDetails = (contactDetail) => {
      if (contactDetail.updatedAt) {
        let lastUpdated =
          (new Date(moment().format("YYYY-MM-DD hh:mm:ss")).getTime() -
            new Date(contactDetail.updateAt).getTime()) /
          1000 /
          60;
        if (lastUpdated < 1)
          toastr.info("Already Updated", "Contact details are already updated");
        else
          dispatch(
            currentProjectActions.msdRefresh(row.id, codeLabels, contactDetail, () => {
              let jsonBody = currentfilter;
              if (searchCharactors.length > 2) {
                jsonBody = {
                  [getSearchBy()]: searchCharactors,
                };
              }
              setTimeout(() => {
                dispatch(projectActions.getProjects(jsonBody));
              }, 1000);
            })
          );
      } else {
        dispatch(
          currentProjectActions.msdRefresh(row.id, codeLabels, contactDetail, () => {
            let jsonBody = currentfilter;
            if (searchCharactors.length > 2) {
              jsonBody = {
                [getSearchBy()]: searchCharactors,
              };
            }
            dispatch(projectActions.getProjects(jsonBody));
          })
        );
      }
    };
    return (
      <Col
        lg={!userRecord.AllActionsAccess ? "4" : "3"}
        md={!userRecord.AllActionsAccess ? "4" : "3"}
        sm="12"
        xs="12"
        className="border-right"
      >
        <Row>
          <Col>
            <h5>Microsoft Dynamics / Client Details</h5>
          </Col>
        </Row>
        {row.ContractDetails
          ? row.ContractDetails.map((cd, index) =>
              app.recordloading && currentContactLoading?.id == cd.id ? (
                <Row className="justify-content-center mt-5">
                  <Spinner size="small" />
                </Row>
              ) : (
                <>
                  <Row className="mb-2">
                    <Col>
                      <Label className="small font-weight-bold mb-0">
                        Opportunity Details #{index + 1}
                      </Label>
                    </Col>
                    {cd.isSF ? (
                      <Col className="text-right">
                        <a
                          className={
                            row?.PriceChange == 4
                              ? "no-actions"
                              : "update-client-details small"
                          }
                          onClick={() => syncClientDetails(cd)}
                        >
                          Refresh MSD Opportunity
                        </a>
                      </Col>
                    ) : null}
                  </Row>
                  <Row className="mb-2">
                    <Col xs="4" className="pr-0">
                      Name
                    </Col>
                    <Col xs="8" className="pl-1">
                      {cd.OpportunityName}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs="4" className="pr-0">
                      Account
                    </Col>
                    <Col xs="8" className="pl-1">
                      {cd.AccountName}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs="4" className="pr-0">
                      Industry
                    </Col>
                    <Col xs="8" className="pl-1">
                      {cd.Industry}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs="4" className="pr-0">
                      OP Number
                    </Col>
                    <Col xs="8" className="pl-1">
                      {cd.OpportunityNumber}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs="4" className="pr-0">
                      Stage
                    </Col>
                    <Col xs="8" className="pl-1">
                      {cd.Stage} ({cd.Probability}
                      {"%"})
                    </Col>
                  </Row>
                  {userRecord.AllActionsAccess ? (
                    <Row className="mb-2">
                      <Col xs="4" className="pr-0">
                        Amount
                      </Col>
                      <Col xs="8" className="pl-1">
                        {cd.Amount} {cd.AmountCurrency}
                      </Col>
                    </Row>
                  ) : null}
                  {/* <Row className="mb-2">
                    <Col xs="4" className="pr-0">
                    Fieldwork Start Date (MSD)
                    </Col>
                    <Col xs="8" className="pl-1">
                      {cd.StartofDelivery
                        ? cd.StartofDelivery.split("T")[0]
                        : "Not available"}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs="4" className="pr-0">
                    Client Delivery Date (EOD) (MSD)
                    </Col>
                    <Col xs="8" className="pl-1">
                      {cd.EndofDelivery
                        ? cd.EndofDelivery.split("T")[0]
                        : "Not available"}
                    </Col>
                  </Row> */}
                  <Row className="mb-2">
                    <Col xs="4" className="pr-0">
                      Estimated Closed Date
                    </Col>
                    <Col xs="8" className="pl-1">
                      {cd.CloseDate
                        ? cd.CloseDate?.split("T")[0]
                        : "Not available"}
                    </Col>
                  </Row>
                  <hr
                    className="mb-0 mt-0"
                    style={{ borderStyle: "dotted" }}
                  ></hr>
                  {cd.opportunityLineItemDetails
                    ? cd.opportunityLineItemDetails.map((opl, pi) => (
                        <>
                          <Row>
                            <Col>
                              <Label className="small font-weight-bold mb-0">
                                Product Details #{pi + 1}
                              </Label>
                            </Col>
                          </Row>
                          <Row className="small">
                            <Col xs="4" className="pr-0">
                              WBS Number
                            </Col>
                            <Col xs="8" className="pl-1">
                              {opl.WBSNumber ? opl.WBSNumber : "Not Available"}
                            </Col>
                          </Row>
                          <Row className="small">
                            <Col xs="4" className="pr-0">
                              Material ID
                            </Col>
                            <Col xs="8" className="pl-1">
                              {opl.MaterialID
                                ? opl.MaterialID
                                : "Not Available"}
                            </Col>
                          </Row>
                          <Row className="small">
                            <Col xs="4" className="pr-0">
                              Product Description
                            </Col>
                            <Col xs="8" className="pl-1">
                              {opl.ProductDescription
                                ? opl.ProductDescription
                                : "Not Available"}
                            </Col>
                          </Row>
                          <Row className="small">
                            <Col xs="4" className="pr-0">
                              Sub Brand
                            </Col>
                            <Col xs="8" className="pl-1">
                              {opl.SubBrand ? opl.SubBrand : "Not Available"}
                            </Col>
                          </Row>
                          <Row className="small">
                            <Col xs="4" className="pr-0">
                              Practice Area
                            </Col>
                            <Col xs="8" className="pl-1">
                              {opl.PracticeArea
                                ? opl.PracticeArea
                                : "Not Available"}
                            </Col>
                          </Row>
                          {row?.CostingProfiles?.filter(
                            (cp) => cp.ProfileStatus != "99"
                          ).map((CostingProfile) =>
                            handleJSONData(
                              CostingProfile?.ProductTableData
                            )?.map((WBSData) => {
                              let methodology = [];
                              let methodologyStr = [];
                              if (
                                WBSData.methodology &&
                                Array.isArray(WBSData.methodology)
                              ) {
                                methodology =
                                  WBSData.methodology &&
                                  WBSData.methodology.map((m) => m.label);
                                methodologyStr = methodology?.join("\r\n");
                              } else {
                                methodologyStr = WBSData.CharacteristicValues
                                  ?.Z_FIELDINGMETHOD[0]
                                  ? WBSData.CharacteristicValues
                                      .Z_FIELDINGMETHOD[0]
                                  : WBSData.methodology;
                              }
                              return opl.OpportunityLineItemID ===
                                WBSData.OpportunityLineItemID ? (
                                <Row className="small">
                                  <Col xs="4" className="pr-0">
                                    Sub Project Type
                                  </Col>
                                  <Col xs="8" className="pl-1">
                                    {WBSData.methodologyType}
                                  </Col>
                                  <Col xs="4" className="pr-0">
                                    Methodology
                                  </Col>
                                  <Col
                                    xs="8"
                                    className="pl-1"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {methodologyStr}
                                  </Col>
                                </Row>
                              ) : null;
                            })
                          )}
                          {/* <Row className="small">
                            <Col xs="4" className="pr-0">
                            Fieldwork Start Date (MSD)
                            </Col>
                            <Col xs="8" className="pl-1">
                              {opl.StartDate
                                ? opl.StartDate.split("T")[0]
                                : "Not available"}
                            </Col>
                          </Row>
                          <Row className="small">
                            <Col xs="4" className="pr-0">
                            Client Delivery Date (EOD) (MSD)
                            </Col>
                            <Col xs="8" className="pl-1">
                              {opl.EndDate
                                ? opl.EndDate.split("T")[0]
                                : "Not available"}
                            </Col>
                          </Row> */}
                        </>
                      ))
                    : "No Products Found"}
                  <hr
                    className="mt-0 mb-0"
                    style={{ borderStyle: "dashed" }}
                  ></hr>
                </>
              )
            )
          : "No Contract Details Found"}
      </Col>
    );
  };

  const costingOverview = (row) => {
    let finalProfile = null;
    const validStatus = ["3", "9", "4", "5", "98", "11"];
    if (_.includes(validStatus, row.ProjectStatus))
      finalProfile = _.head(
        row.CostingProfiles.filter((cp) => cp.ProfileStatus == "6")
      );
    let cvalues = finalProfile?.CostInputCurrency?.split("-");
    let currencyUnit = _.last(cvalues);
    let countryCode = _.head(cvalues);
    let conversionUnit = _.head(
      finalProfile?.ProfileSetting?.CurrenciesData?.filter(
        (cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
      )
    )?.ConversionRateToLocal;
    if (!conversionUnit) conversionUnit = 1;
    if (!currencyUnit) currencyUnit = "USD";

    return (
      <Col lg="3" md="3" sm="12" xs="12" className="border-right">
        {!finalProfile ? (
          <>
            <Row>
              <Col>
                <h5>Costing Overview</h5>
              </Col>
            </Row>
            <Row>
              <Col className="p-3">
                <Row className="justify-content-center text-center">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="text-muted mb-1"
                    size="lg"
                  />
                </Row>
                <Row className="justify-content-center text-center">
                  <h5>Final Summary Not Available</h5>
                </Row>
                <Row className="justify-content-center text-center">
                  Waiting for the project to be Commissioned
                </Row>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col>
                <h5>Costing Overview</h5>
              </Col>
              {!finalProfile.IsImportedProfile ? (
                <Col>
                  <Link
                    className={row.PriceChange <= 3 ? "small" : "no-actions"}
                    onClick={() => {
                      dispatch(
                        currentCostingActions.getSummaryCosting(
                          finalProfile,
                          () => {
                            history.push(`/summary/${finalProfile.id}`);
                          },
                          true
                        )
                      );
                    }}
                    //   to={`/summary/${finalProfile.id}`}
                  >
                    View Details
                  </Link>
                </Col>
              ) : null}
            </Row>
            <Row className="mb-2">
              <Col>Profile #{finalProfile.ProfileNumber}</Col>
              <Col>{finalProfile.ProfileName}</Col>
            </Row>
            <Row className="mb-2">
              <Col>Total Project Value</Col>
              <Col>{`${_.round(
                finalProfile.PriceToClient * conversionUnit,
                2
              )} ${currencyUnit}`}</Col>
            </Row>
            <Row className="mb-2">
              <Col>Total Internal Cost</Col>
              <Col>{`${_.round(
                (finalProfile.TotalInternalCosts ||
                  finalProfile.CostTotalInternalCommercial +
                    finalProfile.CostTotalInternalOperations) * conversionUnit,
                2
              )} ${currencyUnit}`}</Col>
            </Row>
            <Row className="mb-2">
              <Col>Total Third Party Cost</Col>
              <Col>{`${_.round(
                finalProfile.TotalExternalCosts * conversionUnit,
                2
              )} ${currencyUnit}`}</Col>
            </Row>
            <Row className="mb-2">
              <Col>Net Revenue</Col>
              <Col>{_.round(finalProfile.NetRevenuePercent * 100, 2)}%</Col>
            </Row>
            <Row className="mb-2">
              <Col>Out of Pocket</Col>
              <Col>
                {_.round(finalProfile.OutOfPocketCostPercent * 100, 2)}%
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                {finalProfile.CountryContributionMarginTargetPercent===null&&finalProfile.CostingsSheetId!==null
                  ? "EBITDA Margin"
                  : "CM %"}
              </Col>
              <Col>
                {finalProfile.MarginPercent
                  ? _.round(finalProfile.MarginPercent * 100, 2) + "%"
                  : "-"}
              </Col>
            </Row>
            {finalProfile.ApprovalDetails &&
            finalProfile.ApprovalDetails.length &&
            finalProfile.ApprovalDetails.filter(
              (ad) => ad.Order <= finalProfile.ApprovalLevelNeeded
            ).length ? (
              <>
                <Row>
                  <Col>
                    <strong>Approvals needed</strong>
                  </Col>
                </Row>
                {finalProfile.ApprovalDetails.map((ad) => (
                  <>
                    <Row className="ml-1 mt-2">
                      <strong>{ad.Label}</strong>
                    </Row>
                    {ad.ApproverContacts?.map((contact) =>
                      contact.Order <= finalProfile.ApprovalLevelNeeded ? (
                        <Row>
                          <Col>
                            {contact.Approved ? (
                              <FontAwesomeIcon
                                icon={faThumbsUp}
                                className="text-muted align-middle mr-2"
                                title="Appproval Granted"
                                size="sm"
                              />
                            ) : contact.Denied ? (
                              <FontAwesomeIcon
                                icon={faThumbsDown}
                                title="Appproval Denied"
                                className="text-muted align-middle mr-2"
                                size="sm"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faHourglassHalf}
                                title="Appproval Awaited"
                                className="text-muted align-middle mr-2"
                                size="sm"
                              />
                            )}
                            {contact.EmailAddress}
                            {contact.IsMandatoryApprover ? (
                              <FontAwesomeIcon
                                icon={faExclamationCircle}
                                title="Mandatory Approver for this level."
                                className="text-muted align-middle ml-2"
                                size="sm"
                              />
                            ) : null}
                          </Col>
                        </Row>
                      ) : null
                    )}
                  </>
                ))}
              </>
            ) : (
              <div className="mt-2">
                <strong>No Approvals were required for this Costing</strong>
              </div>
            )}
          </>
        )}
      </Col>
    );
  };

  const confirmCopyProposal = () => {
    setCopiedPopUp(false);
    dispatch(
      currentProjectActions.setCurrentProject({
        ...project,
        copiedProject: false,
      })
    );
    dispatch(projectActions.getProjects({ myProject: true }));
  };
  const timeline = (row) => {
    let finalProfile = null;
    const validStatus = ["3", "9", "4", "5", "98", "11"];
    if (_.includes(validStatus, row.ProjectStatus))
      finalProfile = _.head(
        row.CostingProfiles.filter((cp) => cp.ProfileStatus == "6")
      );
    let waveSpecs = finalProfile?.WaveSpecs;
    var currentwave = _.head(waveSpecs);
    const waveChanged = (val) => {
      currentwave = _.head(waveSpecs.filter((ws) => ws.id == val));
    };

    return (
      <Col
        lg={!userRecord.AllActionsAccess ? "3" : "2"}
        md={!userRecord.AllActionsAccess ? "3" : "2"}
        sm="12"
        xs="12"
        className="border-right"
      >
        {waveSpecs && waveSpecs.length ? (
          <SchedulesData
            wavespecs={waveSpecs}
            profile={finalProfile}
            projecUpload={row.IsUploaded}
            setUploadProject={setUploadProject}
            ParentProjectId={row.ParentProjectId}
            IsCopy={row.IsCopy}
            IsSyndicated={row.IsSyndicated}
            PriceChange={row?.PriceChange}
          />
        ) : (
          <>
            <Row>
              <Col>
                {row.CostingProfiles.length > 0 ? (
                  row.CostingProfiles[row.CostingProfiles.length - 1]
                    .CommissionedDate ? (
                    <Row className="justify-content-center text-center">
                      <Col>
                        {/* {row.CostingProfiles.map((key) => {
                          {console.log(key)}
                          {console.log(key.ProfileNumber)}
                          if (!(key.CommissionedDate)){
                            key.CommissionedDate = "-"
                          } else {
                          return(
                            <Row>
                            Commissioned profile #{key.ProfileNumber} :{" "}{key.CommissionedDate}{" "}
                            </Row>
                          )}
                          
                            })} */}
                        {row.IsDeleted != true && row.ProjectStatus != 8 ? (
                          <Row>
                            <Col>Commissioned in system :</Col>
                            {
                              new Date(
                                row.CostingProfiles[
                                  row.CostingProfiles.length - 1
                                ].CommissionedDate
                              )
                                .toISOString()
                                .split("T")[0]
                            }
                          </Row>
                        ) : null}
                      </Col>
                    </Row>
                  ) : null
                ) : null}
                <br></br>
                <h5>Timeline</h5>
              </Col>
            </Row>
            {!(row.ParentProjectId && row.IsSyndicated && !row.IsCopy) && (
              <Col className="p-3">
                <Row className="justify-content-center text-center">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="text-muted mb-1"
                    size="lg"
                  />
                </Row>
                <Row className="justify-content-center text-center">
                  <h5>Schedule Not Available</h5>
                </Row>
                <Row className="justify-content-center text-center">
                  Waiting for the project to be Commissioned
                </Row>
              </Col>
            )}
          </>
        )}
      </Col>
    );
  };
  const actions = (row) => {
    const validStatus = ["3", "9", "4", "5", "98", "11"];
    let finalProfile = null;
    const recordload = () => {
      dispatch(pageLoadStart());
    };
    const CopyProposal = () => {
      dispatch(pageLoadStart());
      let url =
        selectedProject.PriceChange === 2 || selectedProject.PriceChange === 3
          ? "projects/UnlockProject/"
          : "projects/CopyProposal/";
      Axios.post(url + row.ProjectId, { isCopy: true })
        .then((res) => {
          //dispatch(currentProjectActions.setCurrentProjectCreatedFlag());//for popup flag true
          dispatch(
            currentProjectActions.setCurrentProject({
              ...res.data.Project,
              copiedProject: true,
              IscopiedProject: true,
            })
          ); // for pop up details
          dispatch(pageLoadEnd());
          toastr.success("Proposal Copied Successfully!");
        })
        .catch((error) => {
          dispatch(pageLoadEnd());
          toastr.error("Coping Proposal Failed!");
        });
    };
    if (_.includes(validStatus, row.ProjectStatus))
      finalProfile = _.head(
        row.CostingProfiles.filter((cp) => cp.ProfileStatus == "6")
      );

    // const hendleuplodeproject=()=>{
    //   // debugger
    //   return <UploadedProjectModal uploadProject={uploadProject} setUploadProject={setUploadProject}  />

    // }
    let commissionedDate = new Date(finalProfile?.CommissionedDate);
    let actualDate = getLabel("CommissionedFlagOptions", "CommissionedFlag");
    actualDate = new Date(actualDate);

    return (
      // app.recordloading?
      // <Spinner size="small" color="#495057" />
      //  :
      <Col lg="1" md="1" sm="10" xs="10">
        <Row>
          <Col>
            <h5>Actions</h5>
          </Col>
        </Row>
        <Row>
          <Col xs="1">
            <Link
              to="/proposal-new"
              onClick={(e) => {
                gaEventTracker(EventName.EditProposal);
                dispatch(currentProjectActions.getProject(row.ProjectId));
              }}
              className={
                (
                  row?.PriceChange == 4
                    ? "no-actions"
                    : row?.PriceChange == 3 &&
                      (row.ProjectStatus !== "3" ||
                        row.ProjectStatus !== "9") &&
                      row.ProjectStatus !== "1"
                    ? "no-actions"
                    : userRecord.IsOpsProjectManager &&
                      !userRecord.AllActionsAccess
                )
                  ? "no-actions"
                  : ""
              }
            >
              <FontAwesomeIcon
                icon={faPen}
                className="ic-hover"
                fixedWidth
                title="Edit Proposal"
              />
              {/* <EditFill title="Edit Proposal" /> */}
            </Link>

            {/* <FontAwesomeIcon icon={faCommentDollar} fixedWidth />
            <FontAwesomeIcon icon={faAddressCard} fixedWidth />
            <FontAwesomeIcon icon={faUserPlus} fixedWidth />
            <FontAwesomeIcon icon={faFilePdf} fixedWidth /> */}
            <Link
              className={
                (
                  row?.PriceChange == 4
                    ? "no-actions"
                    : row?.PriceChange == 3 &&
                      (row.ProjectStatus !== "3" ||
                        row.ProjectStatus !== "9") &&
                      row.ProjectStatus !== "1"
                    ? "no-actions"
                    : userRecord.IsOpsProjectManager &&
                      !userRecord.AllActionsAccess
                )
                  ? "no-actions"
                  : ""
              }
            >
              <FontAwesomeIcon
                icon={faFolder}
                fixedWidth
                title="Open Project Resources Folder"
                className="ic-hover"
                onClick={() => {
                  gaEventTracker(EventName.OpenProjectFolder);
                  if (row.IsUploaded) {
                    setUploadProject(true);
                  } else {
                    dispatch(
                      sheetCall.msSheetAPiCall(
                        row.ProjectResourcesFolderId,
                        false,
                        uploadProject
                      )
                    );
                  }
                }}
              />
            </Link>
            <Link
              className={
                (
                  row?.PriceChange == 4
                    ? "no-actions"
                    : row?.PriceChange == 3 &&
                      (row.ProjectStatus !== "3" ||
                        row.ProjectStatus !== "9") &&
                      row.ProjectStatus !== "1"
                    ? "no-actions"
                    : userRecord.IsOpsProjectManager &&
                      !userRecord.AllActionsAccess
                )
                  ? "no-actions"
                  : ""
              }
            >
              <FontAwesomeIcon
                icon={faFolderO}
                fixedWidth
                title="Open Costings Folder"
                className="ic-hover"
                onClick={() => {
                  gaEventTracker(EventName.OpenCostingfolder);
                  // window.open(
                  //  "https://drive.google.com/drive/folders/" +
                  //  row.CostingsFolderId
                  // );
                  if (row.IsUploaded) {
                    setUploadProject(true);
                  } else {
                    dispatch(
                      sheetCall.msSheetAPiCall(row.CostingsFolderId, false)
                    );
                  }
                }}
              />
            </Link>
            {/* {userRecord.IsOpsProjectManager &&
            ((row.ParentProjectId && row.IsCopy) || !row.ParentProjectId) ? (
              <Link
                title="Assign Project Manager"
                className={
                  row?.PriceChange == 4
                    ? "no-actions"
                    : row?.PriceChange == 3 && row.ProjectStatus !== "3"&& row.ProjectStatus!=="1"
                    ? "no-actions"
                    : row.IsMigrated || row.ProjectStatus == "7"
                    ? "no-actions"
                    : ""
                }
                onClick={() => {
                  gaEventTracker(EventName.AssignProjectManager);
                  if (row.IsUploaded) {
                    setUploadProject(true);
                  } else {
                    setCurrentProject(row);
                    setShowContractDetails(true);
                  }
                  // dispatch(currentProjectActions.getOnlyProject(row.ProjectId,
                  //  () => {
                  //    setShowContractDetails(true)
                  //  }, true))
                }}
              >
                <FontAwesomeIcon
                  icon={faUserPlus}
                  fixedWidth
                  className="iconcolor ic-hover"
                />
              </Link>
            ) : null} */}
            {console.log("finalProfilefinalProfile", finalProfile)}
            {/* {finalProfile &&
            canRenderReview(row) &&
            !(row.IsSyndicated && row.ParentProjectId && !row.IsCopy) ? (
              <Link
                title="Project Review"
                className={
                  row?.PriceChange == 4
                    ? "no-actions"
                    : row?.PriceChange == 3 && (row.ProjectStatus !== "3" || row.ProjectStatus !== "9")
                    ? "no-actions"
                    : row.ProjectStatus == "7"
                    ? "no-actions"
                    : row?.ProjectStatus == '8' &&  row?.IsDeleted ==true
                    ?  "no-actions"
                    : ""
                }
                onClick={() => {
                  if (row.IsUploaded) {
                    setUploadProject(true);
                  } else {
                    dispatch(
                      getCosting(
                        finalProfile.id,
                        () => {
                          history.push(`/project-review/${finalProfile.id}`);
                        },
                        true
                      )
                    );
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faClipboardCheck}
                  className="ic-hover"
                  fixedWidth
                />
              </Link>
            ) : null} */}

            {finalProfile &&
            !(row.IsSyndicated && row.ParentProjectId && !row.IsCopy) ? (
              <Link
                to={row.IsUploaded ? "#" : `/schedule/${finalProfile.id}`}
                title="Project Schedule"
                className={
                  row?.PriceChange == 4
                    ? "no-actions"
                    : row?.PriceChange == 3 &&
                      (row.ProjectStatus !== "3" || row.ProjectStatus !== "9")
                    ? "no-actions"
                    : row.ProjectStatus == "7"
                    ? "no-actions"
                    : row?.ProjectStatus == "8" && row?.IsDeleted == true
                    ? "no-actions"
                    : ""
                }
                onClick={(e) => {
                  if (row.IsUploaded) {
                    setUploadProject(true);
                  } else {
                    dispatch(getCosting(finalProfile.id));
                    dispatch(currentProjectActions.getProject(row.ProjectId));
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  fixedWidth
                  className="iconcolor ic-hover"
                />
              </Link>
            ) : null}
            {(row.IsSyndicated && row.ParentProjectId && !row.IsCopy) ||
            row?.PriceChange > 3 ||
            !(
              row.CostingTemplateVersion == "v2.0" ||
              row.PriceChange == 3 ||
              (_.includes(pacificMarkets, row.CommissioningCountry) &&
                row.CostingTemplateVersion != "v2.0" &&
                !row.IsMigrated)
            ) ? null : (
              <Link
                title={
                  row?.PriceChange === 2 || row?.PriceChange === 3
                    ? "Copy Proposal Available only for New Price Change"
                    : "Copy Proposal Available only for New Template"
                }
                onClick={() =>
                  copyLockedProject(
                    row.ProjectId,
                    row.ProjectName,
                    row?.PriceChange
                  )
                }
                className={
                  row?.PriceChange == 3 && row?.ProjectStatus == 3
                    ? row.CostingTemplateVersion == "v2.0" ||
                      (_.includes(pacificMarkets, row.CommissioningCountry) &&
                        row.CostingTemplateVersion != "v2.0" &&
                        !row.IsMigrated)
                      ? row?.PriceChange == 3
                        ? "no-actions"
                        : "pointer"
                      : "no-actions"
                    : "no-actions"
                }
              >
                <FontAwesomeIcon
                  icon={faClone}
                  className="ic-hover"
                  fixedWidth
                />
              </Link>
            )}
            {/* changes */}
            {/* {(row.ProjectStatus == "3" || row.ProjectStatus == "9" || row.ProjectStatus == "11") &&
              // row.DateFieldWorkStartActual == null &&
              !(row.IsSyndicated && row.ParentProjectId && !row.IsCopy) && (
                <Link className={row?.PriceChange == 4 ? "no-actions" : ""}>
                  <FontAwesomeIcon
                    icon={faPenSquare}
                    fixedWidth
                    title="Planned Cost Adjustments"
                    className="ic-hover"
                    onClick={() => {
                      setClickedRow(row.id);
                      dispatch(
                        currentProjectActions.getIndividualProject(
                          row.ProjectId,
                          row.id
                        )
                      );
                      setOpsOpp(true);
                    }}
                  />
                </Link>
              )} */}
            {/* modal */}
            {opsOpp === true && clickedRow === row.id && (
              <TotalOPSOOPModal
                opsOpp={opsOpp}
                setOpsOpp={setOpsOpp}
                profile={finalProfile}
                project={row}
                dashboard={true}
              />
            )}
            {(row.ProjectStatus == "3" || row.ProjectStatus == "4" || row.ProjectStatus == "9" ||  row.ProjectStatus == "11") && (
              <Link
                className={
                  row?.PriceChange == 4
                    ? "no-actions"
                    : row?.ProjectStatus == "8" && row?.IsDeleted == true
                    ? "no-actions"
                    : ""
                }
              >
                <FontAwesomeIcon
                  icon={faCalendarPlus}
                  fixedWidth
                  title="Date Adjustments"
                  className="ic-hover"
                  onClick={() => {
                    setScheduleDate(true);
                  }}
                />
              </Link>
            )}
            <Modal
              isOpen={isScheduleDates}
              toggle={() => setScheduleDate(false)}
              className="modal-xl"
              centered={true}
            >
              <ModalHeader>
                {/* <img alt="Nielsen Logo" src={logo} className="modallogo" /> */}
                <div>
                  <span className="modaltitle modalops">
                    {" "}
                    Edit Schedule Dates
                  </span>
                </div>
              </ModalHeader>
              <ModalBody>
                {finalProfile?.ProductTableData &&
                finalProfile?.ProductTableData != "null" ? ((commissionedDate?.getTime() >= actualDate?.getTime()) ?
                  (<ProjectScheduleWBSLevel
                      profile={finalProfile}
                      project={row}
                      setScheduleDate={setScheduleDate}
                      isScheduleDates={isScheduleDates}
                      getprojectmanagerlist={projectManagersList}
                    ></ProjectScheduleWBSLevel>
                  ) : (
                    <ProjectScheduleAdjustment
                      profile={finalProfile}
                      project={row}
                      setScheduleDate={setScheduleDate}
                      isScheduleDates={isScheduleDates}
                    ></ProjectScheduleAdjustment>
                  )
                ) : (
                  <ProjectScheduleAdjustmentEWN
                    profile={finalProfile}
                    project={row}
                    setScheduleDate={setScheduleDate}
                    isScheduleDates={isScheduleDates}
                  ></ProjectScheduleAdjustmentEWN>
                )}
              </ModalBody>
              <ModalFooter></ModalFooter>
            </Modal>

            {userRecord.CanDeleteProposal ? (
              <Link
                title="Delete Project"
                className={
                  row?.ProjectStatus == "8" && row?.IsDeleted == true
                    ? "no-actions"
                    : row?.ProposalOwnerEmail.value == userRecord.Email ||
                      userRecord.CanDeleteAnyProposal ||
                      row?.OtherProjectTeamContacts.some((item) =>
                        userRecord.Email.includes(item.value)
                      )
                    ? ""
                    : "no-actions"
                }
                onClick={() => {
                  rowData = [];
                  rowData.push(row);
                  gaEventTracker(EventName.DeleteProposal);
                  setDeleteProject(true);
                }}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  fixedWidth
                  color="black"
                  className="ic-trash-hover"
                />
              </Link>
            ) : userRecord.CanDeleteAnyProposal ? (
              <Link
                title="Delete Project"
                className={
                  row?.ProjectStatus == "8" && row?.IsDeleted == true
                    ? "no-actions"
                    : ""
                }
                onClick={() => {
                  rowData = [];
                  rowData.push(row);
                  gaEventTracker(EventName.DeleteProposal);
                  setDeleteProject(true);
                }}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  fixedWidth
                  color="black"
                  className="ic-trash-hover"
                />
              </Link>
            ) : null}
            {!(
              (row.IsSyndicated && row.ParentProjectId && !row.IsCopy) ||
              (_.includes(pacificMarkets, row.CommissioningCountry) &&
                row.CostingTemplateVersion != "v2.0")
            ) && (
              <Link
                className={
                  row.CostingProfiles[row.CostingProfiles.length - 1]
                    ?.CostingsSheetId
                    ? "pointer"
                    : "no-actions"
                }
              >
                <FontAwesomeIcon
                  icon={faFileExcel}
                  title="Open Costings Sheet"
                  fixedWidth
                  className="ic-hover"
                  onClick={() => {
                    dispatch(
                      sheetCall.msSheetAPiCall(
                        row.CostingProfiles[row.CostingProfiles.length - 1]
                          ?.CostingsSheetId,
                        false,
                        true
                      )
                    );
                  }}
                />
              </Link>
            )}
            {(!_.includes(pacificMarkets, row.CommissioningCountry) ||
              (_.includes(pacificMarkets, row.CommissioningCountry) &&
                row.CostingTemplateVersion == "v2.0")) && (
              <Link
                className={
                  row.PriceChange < 3 ||
                  (_.includes(pacificMarkets, row.CommissioningCountry) &&
                    row.CostingTemplateVersion != "v2.0") ||
                  row?.PriceChange == 3
                    ? "pointer"
                    : "no-actions"
                }
              >
                <FontAwesomeIcon
                  icon={faFileInvoiceDollar}
                  fixedWidth
                  title="View Costing Profile Summary"
                  className="ic-hover"
                  onClick={() => {
                    dispatch(
                      currentCostingActions.getSummaryCosting(
                        row.CostingProfiles[row.CostingProfiles.length - 1],
                        () => {
                          history.push(
                            `/summary/${
                              row.CostingProfiles[
                                row.CostingProfiles.length - 1
                              ]?.id
                            }`
                          );
                        },
                        true
                      )
                    );
                  }}
                />
              </Link>
            )}
          </Col>
          <Modal
            isOpen={isLocked}
            toggle={() => setLocked(!isLocked)}
            className="modal-sm"
            centered={true}
          >
            <ModalHeader toggle={() => setLocked(!isLocked)}>
              {/* <img src={logo} className="modallogo" /> */}
            </ModalHeader>
            <div>
              <span className="modaltitle modaltitleDisplay">Copy Project</span>
            </div>

            <ModalBody>
              Are you sure want to copy the project ?
              <p className="mt-1">
                <b>Project Name : {selectedProject.projectName}</b>
              </p>
              <p>
                <b>Project ID : {selectedProject.projectId}</b>
              </p>
              {selectedProject.PriceChange === 2 ||
              selectedProject.PriceChange === 3 ? (
                <p>
                  <b>Note : </b>
                  We will copy only the RFQ + SUD.
                </p>
              ) : (
                ""
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={CopyProposal}>
                Confirm
              </Button>
              <Button onClick={() => setLocked(!isLocked)}>Cancel</Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={deleteProject}
            toggle={() => setDeleteProject(false)}
            className="modal-sm"
            centered={true}
          >
            <ModalHeader>
              {/* <img src={logo} className="modallogo" /> */}
            </ModalHeader>
            <div>
              <span className="modaltitle modaltitleDisplay">
                Delete Project
              </span>
            </div>

            <ModalBody style={{ "margin-left": "10px" }}>
              Reason for deletion
              <Input
                id="ProjectBackground"
                type="textarea"
                value={delReason}
                onChange={(e) => setDelReason(e.target.value)}
              />{" "}
              <br></br>
              Deletion requester:
              <Select
                className="react-select-container mb-3 lg-12 md-12"
                classNamePrefix="react-select"
                options={filterManagerList()}
                isSearchable
                value={delUser}
                onChange={(e) => {
                  setDelUser(e);
                }}
              />
              Deleted by: <br></br>
              <Input
                type="text"
                className="mr-sm-2"
                value={userRecord.EmailId}
                disabled={true}
              />{" "}
              <br></br>
              Date of Deletion <br></br>
              <Input
                type="text"
                className="mr-sm-2"
                value={moment().format("MM-DD-YYYY")}
                disabled={true}
              />
              {/* <DatePicker
                className="form-control"
                selected={
                  delDate
                }
                onChange={(e) => {setDelDate(new Date(e));}}
                dateFormat="dd-MM-yyyy"
                placeholderText="dd-mm-yyyy"
              /> <br></br> */}
              <div style={{ marginTop: "20px" }}>
                Are you sure want to delete the project ?<br></br>
                <b>This action is irreversible</b>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                onClick={() => {
                  if (validateProject()) setConfrimDeleteModal(true);
                  rowData.push(row);
                }}
              >
                Delete Project
              </Button>
              <Button
                onClick={() => {
                  setDeleteProject(false);
                  rowData = [];
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={confirmDeleteModal}
            toggle={() => setConfrimDeleteModal(false)}
            className="modal-lg"
            centered={true}
          >
            {/* <ModalHeader
              toggle={() => setConfrimDeleteModal(!confirmDeleteModal)}
            >  
            </ModalHeader> */}
            <ModalBody>
              <b>
                This project {rowData[0]?.ProjectId} will be deleted.{" "}
                <span style={{ color: "Red" }}>
                  This action is irreversible.{" "}
                </span>
                Please make sure that it does not cause any financial impact
                before proceeding.
              </b>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                onClick={() => {
                  if (validateProject()) {
                    handleDeleteProject(rowData[0]);
                    rowData = [];
                  }
                }}
              >
                I confirm. Delete the project
              </Button>
              <Button
                onClick={() => {
                  setConfrimDeleteModal(false);
                  rowData = [];
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={showContractDetails}
            toggle={() => setShowContractDetails(false)}
            className="modal-md"
            centerd={true}
          >
            <ModalHeader
              toggle={() => setShowContractDetails(!showContractDetails)}
            >
              {/* <img src={logo} className="modallogo" /> */}
              {/* <Row>
                <Col>
                  <h4></h4>
                </Col>
              </Row> */}
            </ModalHeader>
            <Row>
              <Col>
                <div>
                  <span className="modaltitle modaltitlemanager">
                    Select Project Manager
                  </span>
                </div>
              </Col>
            </Row>

            <ModalBody>
              <Select
                className="react-select-container mb-3 lg-12 md-12"
                classNamePrefix="react-select"
                options={projectManagersList?.map((item) => {
                  return { value: item.Email, label: item.Email };
                })}
                isSearchable
                value={
                  currentProject?.ProjectManagerEmail?.length
                    ? currentProject.ProjectManagerEmail
                    : []
                }
                onChange={(e) => {
                  setCurrentProject({
                    ...currentProject,
                    ProjectManagerEmail: e,
                  });
                  // dispatch(currentProjectActions.setCurrentProject({ ...currentProject, ProjectManagerEmail: e }));
                }}
                isMulti
              />
            </ModalBody>
            <ModalFooter>
              <Row className="justify-content-end">
                <Button
                  color="primary"
                  onClick={saveProjectManager}
                  disabled={
                    currentProject?.ProjectManagerEmail?.length == 0 ||
                    currentProject?.ProjectManagerEmail == null
                  }
                >
                  Update
                  {app.recordloading ? (
                    <Spinner size="small" color="#495057" />
                  ) : null}
                </Button>
                <Button
                  className="ml-2"
                  color="secondary"
                  onClick={() => setShowContractDetails(false)}
                >
                  Cancel
                </Button>
              </Row>
            </ModalFooter>
          </Modal>
        </Row>
      </Col>
    );
  };
  const expandRow = {
    renderer: (row) =>
      app.recordloading && !row.isCostingOverviewLoaded ? (
        <Row className="justify-content-center details-section-loading">
          <Spinner size="small" color={COLORS.ACCENT} />
        </Row>
      ) : (
        <div>
          <Row>
            {projectDetails(row)}
            {salesforceColumn(row)}
            {!userRecord.AllActionsAccess ? null : costingOverview(row)}
            {timeline(row)}
            {actions(row)}
          </Row>
        </div>
      ),
    expanded: expandedRowId !== null ? [expandedRowId] : [],
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),
    expandColumnRenderer: ({ expanded }) =>
      expanded ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),
    onExpand: (row, isExpand, rowIndex, e) => {
      if(isExpand) setExpandedRowId(row.id);
      else setExpandedRowId(null);

      let commProfile = null;
      const validStatus = ["3", "9", "4", "5", "98", "11"];
      if (_.includes(validStatus, row.ProjectStatus))
        commProfile = _.head(
          row.CostingProfiles?.filter((cp) => cp.ProfileStatus == "6")
        );
      if (commProfile && !row.isCostingOverviewLoaded) {
        setTimeout(() => {
          dispatch(
            currentProjectActions.getIndividualProject(row.ProjectId, row.id)
          );
        });
      } else if (!commProfile) {
        setTimeout(() => {
          dispatch(currentProjectActions.setOverviewStatus(row));
        });
      }
    },
  };

  const handleJSONData = (data) => {
    let parsedData;

    if (typeof data === "string") {
      try {
        parsedData = JSON.parse(data);
      } catch (err) {
        console.error("Error parsing json", err);
      }
    } else if (typeof data === "object") {
      parsedData = data;
    } else {
      console.error("Invalid data type", typeof data);
    }

    if (typeof parsedData === "string") {
      try {
        parsedData = JSON.parse(parsedData);
      } catch (err) {
        console.error("Error parsing json", err);
      }
    }
    return parsedData;
  };

  return (
    <>
      {uploadProject && (
        <UploadedProjectModal
          uploadProject={uploadProject}
          setUploadProject={setUploadProject}
        />
      )}
      <Modal
        isOpen={copiedPopUp}
        toggle={() => {
          setCopiedPopUp(false);
          dispatch(
            currentProjectActions.setCurrentProject({
              ...project,
              copiedProject: false,
            })
          );
        }}
        size="sm"
        centered={true}
      >
        <ModalHeader
          toggle={() => {
            setCopiedPopUp(false);
            dispatch(
              currentProjectActions.setCurrentProject({
                ...project,
                copiedProject: false,
              })
            );
          }}
        >
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <div>
          <span className="modaltitle modalcopyproposal">
            Proposal Copied Successfully
          </span>
        </div>
        <ModalBody>
          <p>
            <h3>Proposal Details are :-</h3>
          </p>
          <br />
          <p>
            <h4>Project Name: {project.ProjectName}</h4>
            <h4>Project ID: {project.ProjectId}</h4>
          </p>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-between">
            <Button
              className="form-control"
              color="secondary"
              onClick={confirmCopyProposal}
            >
              OK
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Card>
        <CardBody className="p-4">
          <BootstrapTable
            classes="sticky-top-table-next"
            hover
            striped
            bordered={false}
            responsive
            defaultSorted={[{ dataField: "createdAt", order: "desc" }]}
            bootstrap4
            keyField="id"
            data={tableData}
            columns={tableColumns}
            expandRow={expandRow}
            noDataIndication={<b>No data available</b>}
            // pagination={paginationFactory({
            //   sizePerPage: 10,
            //   sizePerPageList: [5, 10, 25, 50],
            // })}
          />
        </CardBody>
      </Card>
    </>
  );
};

const Tables = (props) => {
  const localPageload = useSelector(({ app }) => app.localPageload);
  const recordloading = useSelector(({ app }) => app.recordloading);
  return (
    <Container fluid className="p-0">
      {localPageload || recordloading ? <div id="pageCoverSpin"></div> : null}
      <ExpandableRowsTable {...props} />
    </Container>
  );
};

export default Tables;
